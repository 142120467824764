.markdown {
  h1 {
    font-family: $InterDisplay;
    letter-spacing: $heading_letter_spacing_3xl;
    font-size: $font_size_3xl;
    margin-bottom: 0;
  }
  h2 {
    font-family: $InterDisplay;
    font-size: $font_size_sm;
    letter-spacing: $heading_letter_spacing_sm;
  }
}
