.Modal_m1wjlnqu{max-width:1024px !important;}
.ModalBody_m1q64wuw{padding:18px;padding-top:16px;padding-bottom:0;overflow-y:auto;}
.ModalFooter_mt0s2gk{color:#474952;-webkit-box-pack:justify !important;-webkit-justify-content:space-between !important;-ms-flex-pack:justify !important;justify-content:space-between !important;font-size:0.875rem;margin:0 16px;padding-left:0;padding-right:0;border-top:1px solid #d2d3d8;}
.ModalHeader_mnejkx0{border-bottom:none !important;}
.ModalTopBorder_mai8x9g{background-color:#02a3d5;width:1024px;height:52px;position:absolute;top:0;left:0;}
.ExitModalButton_eyuh9p3{all:unset;height:20px;width:20px;border-radius:50%;background-color:#ffffff;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;z-index:9;}
.ExitModalIcon_e100gl0i{height:20px;width:20px;z-index:10;}.ExitModalIcon_e100gl0i:hover{cursor:pointer;}.ExitModalIcon_e100gl0i circle{stroke:transparent;}.ExitModalIcon_e100gl0i path{fill:#02a3d5;}
.Section_s1onhhiv{padding-top:10px;padding-bottom:10px;border-top:1px solid #d2d3d8;font-size:0.875rem;}
.EntityOverview_e1rtg89k{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;gap:10px;padding-bottom:16px;}
.DowJonesLogo_d4tgusj{height:18px;}
.EntityOverviewDetails_e1wrs16f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.EntityTitle_ervei9v{color:#474952;font-size:1.125rem;line-height:26px;margin-top:-3px;}
.EntityPills_egpr2lq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;}
.CountryFlag_c1frhxeu{width:30px;}.CountryFlag_c1frhxeu img{width:24px !important;height:18px !important;margin:0;margin-top:1px !important;margin-right:8px !important;border-radius:2px !important;}
.CountryName_c1dxpnte{font-size:0.875rem;color:#474952;height:18px;}
.CountryField_c1xb1ka{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-bottom:7px;padding-top:4px;}
.SpecialInterestSection_sc1c375{font-size:0.875rem;padding:8px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.SpecialInterestFlagList_seld6gb{margin:0;padding-left:20px;}
.GridDetailsSection_g19mrmy4.Section_s1onhhiv{display:grid;grid-template-columns:repeat(4,1fr);grid-row-gap:10px;}
.AlternativeNamesSection_apab10v.Section_s1onhhiv{display:grid;grid-template-columns:1fr 1fr;border:none !important;padding-top:5px;}
.Date_ddbl6qf{text-transform:capitalize;color:#474952;}
.DateDuration_ddzl1il{color:#858996;font-size:0.875rem;}
.BulletPoint_b1aynqsg{height:4px;width:4px;display:inline-block;border-radius:50%;margin-right:8px;background-color:#474952;margin-top:3px;margin-bottom:3px;}
.AkaPair_aa0z51r{margin-left:14px;padding-bottom:2px;}
.ListItem_lpxrd52{list-style-type:none;padding-bottom:2px;text-indent:-11px;padding-left:13px;}.ListItem_lpxrd52:not(:first-child){padding-top:12px;}
.List_l2pkhe8{padding-left:0;margin-bottom:0;}
.IdentifierPair_i14e3luu{padding-bottom:2px;}
.SectionTitle_s1ua1w0l{font-size:0.875rem;color:#858996;line-height:18px;padding-bottom:10px;}
.AddressBlock_a1hi3eet{color:#474952;font-size:0.875rem;max-width:220px;display:inline-grid;margin:0 39px 16px 0;}
.ListsSection_l1hh33wp.Section_s1onhhiv > dl:not(:last-child){margin-bottom:8px;}
.FlagsSection_fswgocy.Section_s1onhhiv{padding-top:19px;padding-bottom:8px;}.FlagsSection_fswgocy.Section_s1onhhiv > div{margin-bottom:20px;}.FlagsSection_fswgocy.Section_s1onhhiv > div:last-of-type{margin-bottom:12px;}
.DeprecatedEntityCard_d9uj1o4{margin-bottom:16px;}
.IdentifierKey_i1xk5hiu{color:#858996;}
.LastReviewedLabel_lpmk44h{color:#858996;padding-right:5px;}
.Field_f6tld1o.CountryField_c1xb1ka{padding:0 !important;cursor:pointer;}
.Key_k2ei0hl::first-letter{text-transform:capitalize;}
.Value_v1bxsvd{text-transform:capitalize;color:#474952;}
.LocationsSection_l17921c.GridDetailsSection_g19mrmy4.Section_s1onhhiv{row-gap:10px;}
.EntityImage_e1qyt7h8{background-color:var(--e1qyt7h8-0);height:74px;min-width:74px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:3px;}
.ExpandableHeader_e1x4tvbs{border-top:1px solid #d2d3d8;color:#858996;font-size:1rem;padding:8px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;cursor:pointer;}
.CollapseButton_cyl6rp0{outline:none;background:none;border:none;border-radius:20px;width:28px;height:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:background-color 0.2s;transition:background-color 0.2s;}.CollapseButton_cyl6rp0:focus,.CollapseButton_cyl6rp0:active{outline:none;background-color:#dfe1e3;}
.CollapseArrow_cuioqc1{height:16px;width:16px;-webkit-transform:var(--cuioqc1-0);-ms-transform:var(--cuioqc1-0);transform:var(--cuioqc1-0);}.CollapseArrow_cuioqc1 path{fill:#858996;}
.SourceLink_s1fh3ovz{font-size:0.875rem;}
.Container_cm074gz{padding-bottom:11px;color:#474952;font-size:0.875rem;white-space:pre-wrap;}
.FlagBanner_fcg0usw{border-top-left-radius:11px;border-top-right-radius:11px;border-bottom:none !important;border:1px solid var(--fcg0usw-0);color:var(--fcg0usw-0);font-size:1rem;background-color:#ffffff;padding:0 16px;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.StatusLabel_s119b8ri{font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.FlagLocations_f1ynjen3{border-top:1px solid #d2d3d8;font-size:0.875rem;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;padding-top:7px;padding-bottom:5px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;row-gap:16px;}.FlagLocations_f1ynjen3 > div{margin-right:55px;}
.FlagLocation_fqnoad7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;cursor:pointer;}
.Country_ctl3x0m.CountryField_c1xb1ka{padding-top:0;padding-left:10px;}
.FlagBannerTitle_f1fm7u66{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:36px;}
.Note_n1f6v1qk{text-align:left;}
.CountryFlagTooltip_coevk1v{width:365px;padding:4px;text-align:left;font-size:0.875rem;}
.CountryFlagTooltipHeader_c1lifwy6{border-bottom:1px solid #d2d3d8;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:24px;}.CountryFlagTooltipHeader_c1lifwy6:first-letter{text-transform:capitalize;}
.CountryFlagTooltipContent_c13k8v36{padding-top:4px;}
.IdentifiersSection_irlvanh{padding-top:0;margin-top:-5px;padding-bottom:10px;padding-top:0;}.IdentifiersSection_irlvanh > dl{margin-top:10px;}.IdentifiersSection_irlvanh dl:first-of-type{margin-top:0 !important;}
.WebsitesContainer_w19u2oai{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.AdditionalCount_a41m8cr{color:#858996;white-space:pre;}
.AdditionalInfo_a1ilfcq7{color:#858996;padding-bottom:4px;}
.AddressesSection_a19wx3no{padding-bottom:10px;}
.ImageLinks_ikiw9s8{padding-bottom:10px;margin-top:-5px;}
.PlaceholderText_prt2gmr{color:#b3b5bd;padding:10px 0;}
.Tooltip_t4u2pww{display:inline;}
.NoneIdentified_n1rv7uq5{color:#b3b5bd;font-style:italic;}
.CountryNames_c1qkvqi4{font-size:0.875rem;color:#858996;padding-top:4px;}
.SpecialInterestFlagContent_sz14c2p{padding:10px 0;}
.BackButtonText_b127n35k{color:#ffffff;font-size:1.125rem;line-height:20px;}
.BackButton_bmuqvnz{all:unset;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;position:absolute;top:17px;left:20px;}.BackButton_bmuqvnz:focus{outline:none;}.BackButton_bmuqvnz:hover{cursor:pointer;}.BackButton_bmuqvnz:hover .BackButtonText_b127n35k{border-bottom:1px solid #ffffff;}
.BackButtonChevron_bbnai09{margin-top:1px;height:18px;-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);}
.ModalHeaderTextContainer_ml268ul{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;position:absolute;top:16px;left:20px;}
.ModalHeaderTextPrimary_m103q6mt{font-size:1.125rem;color:#ffffff;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.ModalHeaderTextSecondary_mvso5li{font-size:1.125rem;color:#ffffff;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;opacity:0.6;}
.RoleDetailsSection_r1c0hysb.Section_s1onhhiv{display:grid;grid-template-columns:25% 50% 25%;grid-row-gap:10px;}
.Divider_d1r4fvn9{width:100%;margin:10px 0;height:2px;border-top-color:#d2d3d8;}
.Category_c173scyx{font-size:0.875rem;color:#474952;}
.LowerThresholdItem_l22f8yo{list-style-type:none;padding:0;font-size:0.875rem;color:#474952;padding-left:1rem;font-weight:bold;}.LowerThresholdItem_l22f8yo::before{content:"– ";}
.modalContent_m1pztca8{border-radius:11px;border:none;}
.dataField_d1vrwr19 dt{margin-bottom:10px;}
.countryFlagDataField_c151fep9 dt{margin-bottom:5px;}
.flagPillsContainer_f1wyy51f{padding-top:0 !important;display:block !important;}.flagPillsContainer_f1wyy51f > div:not(:last-child){margin-bottom:12px;}
.associates_a1oz8u4m{padding-top:0;}.associates_a1oz8u4m:last-of-type{padding-bottom:10px;}
