.ModalContent_m16g1tju{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;max-height:34.375rem;overflow:auto;}
.Text_t1hcf8go{color:#b3b5bd;font-size:0.875rem;line-height:1.375rem;margin:0;}
.CustomButton_cxfw44y{-webkit-align-self:flex-start;-ms-flex-item-align:start;align-self:flex-start;}
.Form_fld7exn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.FieldContainer_f133ia64{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;max-height:4.8125rem;}
.Field_f163gad0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:6px;background:#EDEDED;font-style:normal;font-weight:400;font-size:1rem;line-height:24px;color:#858996;padding:12px;}
.FieldLabel_f1usvfox{color:#ffffff;font-size:0.875rem;}
.ResetPassword_r103bwss{all:unset;color:#007AFF;font-size:0.875rem;cursor:pointer;}.ResetPassword_r103bwss:disabled{color:#b3b5bd;cursor:not-allowed;pointer-events:none;}.ResetPassword_r103bwss:hover{-webkit-text-decoration:underline;text-decoration:underline;}.ResetPassword_r103bwss:not(:focus-visible){outline:none;}
.ResetPasswordText_rdb1p8x{margin:0;color:#b3b5bd;font-size:0.875rem;}
.ResetPasswordSentText_r1fpr21d.ResetPasswordText_rdb1p8x{color:#28effa;}
