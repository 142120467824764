.Card_c1v72n6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;background:#ffffff;border-radius:12px;box-shadow:0 4px 6px rgba(0,0,0,0.1);width:478px;position:relative;overflow:hidden;}
.Title_t16jo7q8{color:var(--t16jo7q8-0);font-size:1.125rem;font-weight:bold;margin:0;}
.CardBody_cohkjx7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;font-size:0.875rem;color:#474952;padding:16px 20px 20px;}
.Header_hxnqief{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:20px;gap:8px;}
.ReviewLabel_r1larpgb{color:#474952;font-size:1rem;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:1.5;}
.Description_d1ku96is{gap:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#808080;background:#F2F2F2;padding:12px 20px;font-size:0.625rem;line-height:1.6;font-family:InterDisplay-Medium,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:bold;}
.Content_cfioqzt{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;max-height:250px;overflow-y:scroll;}
.Footer_f1hl7k62{padding:12px 24px;color:#474952;font-size:0.875rem;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;background:#F2F2F2;}.Footer_f1hl7k62 a{color:inherit;}.Footer_f1hl7k62 a:hover{color:#1F7DD9;}
.IconContainer_iit8fkk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:12px;margin-right:8px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:12px;border-radius:9px;background:#fbeeed;box-shadow:0px 0px 1px 1px rgba(0,0,0,0.25);}.IconContainer_iit8fkk svg{width:24px;height:24px;}.IconContainer_iit8fkk svg path{fill:var(--iit8fkk-0);}
.Disclaimer_dtgoyxw{padding-top:16px;border-top:1px solid #d9d9d9;}.Disclaimer_dtgoyxw span{font-weight:bold;color:#f45b5b;}
.DisclaimerText_d1fjmk2j{margin-bottom:0;text-align:center;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.625rem;font-weight:bold;line-height:1.33333333;color:#474952;}
.DisclaimerContainer_dizv0r6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;gap:8px;margin:8px 0;}
.DisclaimerStars_d1a4a0fk{width:9px;fill:#FFD268;}
