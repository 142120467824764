.expanding-list {
  transition: max-height 0.8s ease-out;
  overflow: hidden;
}
.expand-button {
  background-color: $grey_panel;
  padding: 8px;
  margin-top: 4px;
  color: $grey_dark;
}
