.di-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.di-card-header-title-box {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.di-card-headings {
  display: flex;
  flex-direction: column;
  margin: 0.3rem;
}

.di-card-header-image {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
