.missing-data-warning {
  &-body {
    display: flex;
    flex-direction: row;
  }
  &-col {
    margin: 1px;
    padding: 8px 16px;
    background-color: $grey_panel;
  }
  &-field-heading {
    color: $grey_mid;
    white-space: nowrap;
  }
  &-subheading {
    color: $grey_mid;
    padding: 8px 16px;
  }
}
