$z-index-top: 99;
$z-index-l1: 1;
$z-index-l2: 2;
$z-index-l3: 3;
$z-index-l4: 4;
$z-index-l5: 5;
$z-index-l6: 6; // tooltips
$z-index-l7: 7; // section headers
$z-index-l8: 8; // top header
$z-index-l9: 9; // modal
