.di-checkbox.pretty {
  margin-right: 4px;
  border-radius: 3px;
  border: solid $grey_dark 1px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  .state img {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    padding-top: 2px; /* not sure why the top padding is needed for it to line up nicely*/
  }

  &.di-checkbox-checked {
    border-radius: 3px;
    background-color: $blue_icon;
    border: none;
  }
}

.di-checkbox-indeterminate {
  height: 14px;
  width: 14px;
  position: relative;

  > div {
    background-color: $blue_icon;
    border-radius: 2px;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 10px;
    width: 10px;
  }
}
