.bar-wrapper {
  width: 100%;
  margin-top: 16px;

  .line-height-1 {
    line-height: 1;
  }

  .title {
    font-size: $font_size_xl;
  }

  .bar-info {
    display: flex;
    flex-direction: row;
    position: relative;

    .right {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
    .bar {
      height: 10px;
      margin-right: 1px;

      &:hover {
        cursor: pointer;
      }
    }
    .dark-gray-bar {
      background: $grey_dark;
    }

    .dark-gray-bar-label {
      color: $grey_dark;
    }
    .gray-bar {
      background: $grey_ghost;
    }
    .gray-bar-label {
      color: $grey_mid;
    }
    .orange-bar {
      background: $orange_dark_mid;
    }
    .orange-bar-label {
      color: $orange_dark_mid;
    }
    .red-bar {
      background: $red_regular;
    }
    .red-bar-label {
      color: $red_regular;
    }
    .amber-bar {
      background: $amber_regular;
    }
    .amber-bar-label {
      color: $amber_regular;
    }

    .bar-count {
      position: relative;
      line-height: 1;

      & + span {
        display: none;
        position: absolute;
        line-height: 1;
      }
      & + span.right-aligned {
        right: 0;
      }
      &:hover {
        cursor: pointer;
        & + span {
          display: block;
        }
      }
    }

    .stacked-bar {
      margin-right: 0px !important;
      &:hover {
        & + div span {
          display: block;
        }
      }
    }
  }
}
