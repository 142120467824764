.report-section-row {
  > * {
    margin-top: 0px;
    margin-bottom: 1px;
  }
  .report-section-content-outer {
    border-radius: 3px;
  }
  .report-section-content-outer .report-section-content-innerClear {
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    height: auto;
    color: $grey_hover;
  }
}
