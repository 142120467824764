.report-inner-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $Inter_Regular;
  border-radius: 3px;
  background-color: $grey_panel;
  color: $grey_hover;
  padding: 5px 16px;
  margin-top: 1px;
  margin-bottom: 1px;
  cursor: pointer;
  font-size: $font_size_lg;
  height: 32px;
  font-size: $font_size_xl;

  img {
    width: 24px;
    height: 24px;
  }
  .btn:focus {
    box-shadow: 0 0 !important;
  }
}

// .report-inner-section-header2 {
//   display: flex;
//   justify-content: stretch;
//   align-items: center;
//   padding: 5px;
//   font-size: $font_size_sm;
//   font-family: $Noto_sans;
//   border-radius: 3px;
//   background-color: $grey_panel;
//   color: $grey_hover;
//   margin-bottom: 2px;
//   cursor: pointer;
//   margin-top: 10px;
// }

.report-inner-section-header-title {
  padding-top: 0.15rem;
  flex-grow: 1;
}

.report-inner-section-header-count {
  padding-top: 0.15rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

// .report-inner-section-header2 > * {
//   padding: 3px;
// }

// .report-inner-section-header2 img {
//   width: 24px;
//   height: 24px;
// }

// .report-inner-section-header2 .btn:focus {
//   box-shadow: 0 0 !important;
// }

// .report-inner-section-header3 {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   font-size: $font_size_sm;
//   font-family: $Noto_sans;
//   border-radius: 3px;
//   background-color: $grey_panel;
//   color: $grey_hover;
//   padding: 5px 16px;
//   margin-bottom: 2px;
//   cursor: pointer;
//   margin-top: 5px;
// }
// .report-inner-section-header3 img {
//   width: 24px;
//   height: 24px;
// }
