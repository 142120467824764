/*TEST TOOLTIP CSS  */

[data-tooltip],
.tooltip {
  position: relative;
  cursor: default;
  flex: auto;

  .tooltip-inner {
    position: absolute;
    box-shadow: 5px 5px 5px $grey_hover;
    -webkit-box-shadow: 5px 5px 5px $grey_hover;
    -moz-box-shadow: 5px 5px 5px $grey_hover;
  }
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
.tooltip:not(.bs-tooltip-auto):hover:before,
.tooltip:not(.bs-tooltip-auto):hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:not(.bs-tooltip-auto):focus:before,
.tooltip:not(.bs-tooltip-auto):focus:after,
[data-tooltip]:focus-within:before,
[data-tooltip]:focus-within:after,
.tooltip:not(.bs-tooltip-auto):focus-within:before,
.tooltip:not(.bs-tooltip-auto):focus-within:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  /*opacity: 1;*/
}

/* Base styles for the tooltip's directional arrow */
/*.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";

}*/

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  margin: 5px;
  z-index: $z-index-top;
  padding: 8px;
  width: 170px;
  background-color: $white;
  /*background-color: $tooltip_border;*/
  color: $purple_dark;
  z-index: 9999;
  content: attr(data-tooltip);
  font-size: $font_size_sm;
  line-height: 1.2;
  border-radius: 4px;
  opacity: 1;
  box-shadow: 5px 5px 5px $grey_hover;
  -webkit-box-shadow: 5px 5px 5px $grey_hover;
  -moz-box-shadow: 5px 5px 5px $grey_hover;
}

/* Directions */

.tooltip-bottom.directRiskTooltip {
  display: flex;
}

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: $tooltip_border;
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -85px; // This is half of the width of tooltip
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
[data-tooltip]:focus-within:before,
[data-tooltip]:focus-within:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip:focus-within:before,
.tooltip:focus-within:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after,
.tooltip-top:focus-within:before,
.tooltip-top:focus-within:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: $black;
  border-left-color: $tooltip_border;
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after,
.tooltip-left:focus-within:before,
.tooltip-left:focus-within:after {
  opacity: 1;
  transition: opacity 0.2s linear 1s;
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  display: flex;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: $black;
  border-bottom-color: $tooltip_border;
  display: flex;
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after,
.tooltip-bottom:focus-within:before,
.tooltip-bottom:focus-within:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: $black;
  border-right-color: $tooltip_border;
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after,
.tooltip-right:focus-within:before,
.tooltip-right:focus-within:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: 50%;
  margin-top: 50%;
  height: auto;
  padding: 10px;
  opacity: 0;
}

.tooltip-bottom .inputError {
  display: block;
  visibility: visible;
}

/* new react-texty tooltip */
[data-texty] {
  display: block;
  overflow: hidden;

  /* hack needed to stop it cutting the front of upper case J's off - seen in business associate section */
  padding-left: 0.3rem;
  margin-left: -0.3rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  &::after {
    content: "";
    display: block;
  }
}

.tippy-popper {
  max-width: 768px !important;
}

.tooltip-custom-theme,
[data-texty-tooltip] {
  z-index: 1050 !important;
  padding: 14px !important;
  font-size: $font_size_sm !important;
  border-radius: 12px !important;
  font-family: $Inter_Light !important;
  color: $grey_dark !important;
  background-color: $white !important;
  box-shadow: 0 4px 20px 4px rgba(0, 20, 60, 0.1),
    0 4px 80px -8px rgba(0, 20, 60, 0.2) !important;
  -webkit-box-shadow: 5px 5px 5px $grey_hover;
  -moz-box-shadow: 5px 5px 5px $grey_hover;
  overflow-wrap: break-word; // breaks on spaces if poss, if not will break within words
  max-width: 400px; // don't want to take up too much width - looks naff
}

.tooltip-inspector-theme {
  border-radius: 12px !important;
  padding: 0 !important;
}

.tooltip-popover-theme,
[x-circle] {
  border-radius: 10px !important;
  background-color: $gray_lighter !important;
}

[data-texty-tooltip] {
  margin: 6px 0;
}

[data-texty-arrow] {
  position: absolute;
  bottom: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0;
  border-color: $white transparent transparent;
}

[data-texty-arrow*="bottom"] {
  top: -6px;
  bottom: inherit;
  transform: rotate(180deg);
}

/**
  This is for the source tooltips in "How they are described".
  It is used to increase the width of the container, so that the
  tooltip doesn't get clipped when the viewport is resized.

  The reason we do this is because we wrap a small element, therefore
  the container boundary doesn't get hit (causing react-tippy to move it into view) before the tooltip is clipped.
*/
.tooltip-org-described-theme {
  width: 450px !important;
}

/* disable the builtin tooltip for truncated text on Safari */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @supports (not (-ms-ime-align: auto)) {
    .EllipsisText::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
    }
  }
}
