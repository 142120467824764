.Actions_a2gl4rw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;gap:1rem;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;padding-top:2rem;}
.CloseButton_c1afpdm5{background:#EDEDED;padding:12px 24px;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:600;}.CloseButton_c1afpdm5:hover{background:#B8B8B8;}
.SubmitButton_s1jgxe2d{background:var(--s1jgxe2d-0);color:#ffffff;padding:12px 24px;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:600;min-width:80px;}.SubmitButton_s1jgxe2d:hover{background:var(--s1jgxe2d-1);}
.ContextTextArea_c156gtoe{border:1px solid var(--c156gtoe-0);width:100%;height:180px;border-radius:4px;padding:1rem;overflow-y:scroll;border-radius:6px;background:#EDEDED;box-shadow:0px 0px 1px 0px rgba(0,0,0,0.25) inset;color:#858996;}
.ContentWrapper_c1kxz152{pointer-events:none;}.ContentWrapper_c1kxz152 h3{color:#858996;}
.SuccessModalContainer_s1fqhs5t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:12px;padding:24px 8px 8px 8px;height:268px;}
.IconContainer_ib9c3u0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:8px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border-radius:48px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:var(--ib9c3u0-0);}
.SuccessText_s1lwfck0{font-size:1.125rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:inherit;}
.SuccessTextContainer_s1j6n39x{grid-gap:20px;gap:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.SuccessSubText_s1w7bxs5{font-size:1rem;}
.SubTitleText_so9fppp{font-size:0.875rem;}
