.ManageOrganisation_m1vid7f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:1rem;height:90vh;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.ManageOrganisationInnerContainer_m3wynkl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;}
.CustomTitleCard_c11rqv7z{background-color:#243c59;}.CustomTitleCard_c11rqv7z h5 + div{color:#ffffff;}
.OrgDetails_o1je1xbn{margin:0;color:#d2d3d8;margin-left:3.75rem;}
.InputGroup_i5yz45z{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.UsagePanel_u2bbp7g{background-color:#243c59;margin-top:0.75rem;}
.Controls_cnbbuvu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-self:flex-end;-ms-flex-item-align:end;align-self:flex-end;width:100%;}
.SubmitButton_s1xoun5c{-webkit-flex:1;-ms-flex:1;flex:1;}
.MfaButton_m1amnvm6{border:1px solid transparent;color:#ffffff;background:var(--m1amnvm6-0);}.MfaButton_m1amnvm6:hover{background:var(--m1amnvm6-1);}
.LeftControls_l1gu9sr9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1.25rem;}
.ErrorFallback_e1wlpogj{height:90vh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding-bottom:12.5rem;color:#b3b5bd;font-size:1.5rem;gap:1rem;}
.BuildingIcon_br8e4b3{-webkit-transform:scale(0.875);-ms-transform:scale(0.875);transform:scale(0.875);}
.Text_trei03s{color:#b3b5bd;font-size:0.875rem;line-height:1.375rem;margin:0;}
.ModalContent_m1of97mk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;max-height:34.375rem;overflow:auto;}
