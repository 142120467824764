.report-tags {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;

  .tag-container {
    cursor: default;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;

    .tag {
      background-color: $grey_card_hover;
      border-radius: 4px;
      padding: 4px 12px;
      display: flex;
      align-items: center;
      margin-right: 12px;

      img.has-tag-text {
        margin-right: 12px;
      }
      .tags__text {
        margin-right: 12px;
      }
    }

    .tag-social {
      background-color: $tag_container_background;
      color: $white;
    }

    .tag-subtext {
      color: $grey_mid;
    }
  }
}
