.PersonalDetailsContainer_p1biiyfq{display:grid;grid-template-areas: "details-1 details-1 details-2 details-2 online-presence online-presence reach reach" "describe-by-others describe-by-others describe-by-others describe-by-others describe-by-others describe-by-others describe-by-others describe-by-others";grid-template-columns:repeat(8,1fr);grid-template-rows:auto;}
.GridCell_g1hyqqse{padding:16px;background-color:#ffffff;border:1px solid #EDEDED;}
.PersonImage_p2zfhzv.GridCell_g1hyqqse{position:relative;grid-area:person-image;border-top:none;padding:8px;border-right:none;border-left:var(--p2zfhzv-0);border-bottom:1px solid #D2D3D8;width:142px;}
.Details1_d15p2cpq.GridCell_g1hyqqse{grid-area:details-1;}
.Details2_d1sw37w4.GridCell_g1hyqqse{grid-area:details-2;}
.OnlinePresence_oenz27r.GridCell_g1hyqqse{grid-area:online-presence;min-height:280px;}
.Reach_r198u8t5.GridCell_g1hyqqse{grid-area:reach;}
.PersonDescription_pu2lapf.GridCell_g1hyqqse{grid-area:describe-by-others;}
.ReachTitle_rlerjqz{color:#858996;font-size:0.875rem;padding-bottom:4px;}
.ScaleItem_s1xd3tum{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:9px;}
.ScaleIconContainer_s1egwczm{position:relative;}
.ScaleHeader_s3cr6a0{font-size:0.875rem;padding:0 4px;padding-left:6px;color:var(--s3cr6a0-0);font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.ScaleDetail_s1lij0ue{font-size:0.875rem;color:var(--s1lij0ue-0);}.ScaleDetail_s1lij0ue span{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:600;padding:0 4px;}
.ScaleSubtext_s1xjd3m5{font-size:0.875rem;color:var(--s1xjd3m5-0);}
.DirectorshipsIcon_d1rjq1o5{width:30px;height:30px;}.DirectorshipsIcon_d1rjq1o5 path{fill:var(--d1rjq1o5-0);}
.CountriesIcon_c1yx48cl{width:30px;height:30px;}.CountriesIcon_c1yx48cl path{fill:var(--c1yx48cl-0);}
.SocialReachIcon_s3m7hc2{width:30px;height:30px;}.SocialReachIcon_s3m7hc2 path{fill:var(--s3m7hc2-0);}
.DescriptionTitleContainer_d1r7otue{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;font-size:0.875rem;}
.DescriptionTitle_dz6uobl{color:#858996;}
.DescriptionContainer_d1bg0nyv{font-size:0.875rem;margin-bottom:0;overflow-y:auto;}
.QuotesIcon_q1lwbivx{float:left;margin:5px 10px 0 0;}.QuotesIcon_q1lwbivx path{fill:var(--q1lwbivx-0);}
.ScaleSubtextWithInfoIcon_slneqir.ScaleSubtext_s1xjd3m5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.PersonalDetailsContent_p2dkexv{background-color:#EDEDED;padding:16px 16px 16px 16px;height:100%;}.PersonalDetailsContent_p2dkexv dt{margin-bottom:0;}
.CustomSectionFooter_c1ahb778{border-top:1px solid #ffffff;}
.BioText_box0xij{margin-right:5px;}
.SocialReachTooltipList_s3bzoj0{padding-top:8px;padding-left:25px;margin:0;}
.NoSocialPresenceLabel_n1ea91oa{margin:0;padding-bottom:8px;}
.SmallSocialReachIconInfographic_stvesn1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SmallSocialReachIconInfographic_stvesn1 .SocialReachIcon_s3m7hc2{height:35px;width:35px;padding-bottom:0;}.SmallSocialReachIconInfographic_stvesn1 .ScaleHeader_s3cr6a0{padding:0 5px;}
.RiskCountIcon_r1ql4y3e{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;aspect-ratio:1;min-width:21px;border-radius:50%;font-size:0.625rem;background-color:#ef7273;line-height:17px;color:#ffffff;border:2px solid #EDEDED;padding:var(--r1ql4y3e-0);padding-left:var(--r1ql4y3e-1);position:absolute;bottom:-6px;right:-3px;}.RiskCountIcon_r1ql4y3e:hover{cursor:pointer;}
.TooltipContent_t1inz4ub{text-align:left;padding:8px;max-height:325px;overflow-y:auto;width:240px;}
.TooltipHeader_t1ab1hbc{border-bottom:1px solid #d2d3d8;padding-bottom:6px;}
.TooltipBody_t1i5bjh7{max-height:200px;overflow-y:auto;padding-top:5px;}
.RiskyCountries_rg2qh7r{margin-bottom:8px;line-height:1.3;font-size:0.875rem;}
.RiskAssigners_r7ub3j9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-top:8px;gap:8px;}
.OfficershipCard_oqx6z2l{background-color:#EDEDED;border-radius:6px;padding:16px;}.OfficershipCard_oqx6z2l:not(:last-of-type){margin-bottom:8px;}
.Name_n15r1wv{family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;height:20px;line-height:20px;width:100%;}
.CompanyLocation_cmlkgnw{color:#858996;}
.RoleDate_rdcv58u{color:var(--rdcv58u-0);}
.RoleDuration_r1kffx60{font-size:0.875rem;color:#858996;}
.Officerships_ort1phq{margin-top:6px;font-size:0.875rem;}
.CompanyDetails_c1nu7tgx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.PersonDetails_purmktv{font-size:0.875rem;border-top:1px solid #d2d3d8;padding-top:10px;margin-top:12px;}
.CompanyDetailsLeftContent_c1oywyhb{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-width:55%;}
.DetailsContainer_drpnct9{margin-top:-4px;width:100%;}
.Role_r15w0nmk{color:#858996;}
.OfficershipRole_ou1xx53 + .OfficershipRole_ou1xx53{margin-top:4px;}
.SectionLinkButton_s15q19ya{border-bottom:1px solid #858996 !important;color:#858996 !important;font-size:0.875rem !important;line-height:13px;}
.SectionTitle_susoq7g{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;font-weight:inherit;color:#858996;margin-bottom:4px;}
.CountryFlag_c1g4gy0p{width:30px;}.CountryFlag_c1g4gy0p img{width:24px !important;height:18px !important;margin:0;margin-right:8px !important;border-radius:2px !important;}
.NationalityContainer_n10183u0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.RelatedCountryHeader_rzji9x7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.List_lczu2kd{margin-top:11px !important;padding-top:14px !important;padding-left:0 !important;border-top:1px solid #d2d3d8;list-style:none;}
.ListItem_l8duw17{font-size:0.875rem;}.ListItem_l8duw17 + .ListItem_l8duw17{margin-top:12px;}
.NoneIdentified_n3cg0ud{color:#b3b5bd;font-size:0.875rem;font-style:italic;}
.SectionLinkText_s1z06szp{white-space:nowrap;}
.CustomSourceList_cypx0mv{overflow:inherit;}
.IndirectExplainer_ie7okz4{padding-top:8px;}
.TopBar_t9cf6yr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}
.termClassName_t1rhvw7r{margin:0;min-height:22px;line-height:20px;}
.detailClassName_dyjyfi9{min-height:22px;line-height:20px;}
.locationRiskTag_l18oq40j{border-radius:11px;height:23px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:8px;opacity:1 !important;background-color:#ef7273 !important;}.locationRiskTag_l18oq40j > svg{margin-top:0px;margin-right:-3px;}
