.scroll-to-bottom {
  position: fixed;
  bottom: 2px;
  border-radius: 3px;
  z-index: $z-index-top;
  border: 1px solid $white;
  padding: 1px 6px;
  width: 26px; // this is referenced in section.js, make sure its same
  height: 26px;
  background: $grey_panel;

  &:hover {
    background-color: $blue_hover;
    cursor: pointer;
  }
}
