.Overview_o10x26vh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.OverviewDetails_oftqsx5{margin-left:12px;}
.OverviewImageContainer_o66lp90{border-radius:3px;width:74px;height:74px;background-color:var(--o66lp90-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:8px;}
.Content_csc8dpm{font-size:0.875rem;color:#474952;max-height:calc( 100vh - 9rem );overflow-y:auto;}
.OverviewTitle_oh9r9qf{font-size:1.125rem;margin-top:-6px;}
.Industry_ie3zqa{margin-top:4px;}
.IndustryType_ij23oft{color:#858996;}
.OrgLocation_o1ja711{margin-top:4px;}
.OrgTags_o3atity{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:6px;margin-top:8px;}
.Pill_p12fhg9k{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:22px;padding:0px 8px;border-radius:16px;margin-right:6px;-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;font-size:0.875rem;max-width:165px;}
.RiskPill_rwgw2lm.Pill_p12fhg9k{border:1px solid #ef7273;color:#ef7273;}
.StatusPill_s8v9eyt.Pill_p12fhg9k{border:1px solid var(--s8v9eyt-0);color:var(--s8v9eyt-0);opacity:0.6;}
.Section_s158zp9i{border-top:1px solid #d2d3d8;margin-top:16px;padding-top:10px;}
.OrgDetails_o1u2flfn.Section_s158zp9i{display:grid;grid-template-columns:repeat(4,1fr);}
.DataKey_dez9iew{color:#858996;margin-bottom:4px;}
.Addresses_a11iztu2.Section_s158zp9i{display:grid;grid-template-columns:repeat(4,1fr);}
.ImageContainer_iimwkud{border-radius:6px;background-color:var(--iimwkud-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:123px;width:232px;margin-top:5px;}.ImageContainer_iimwkud img{border-radius:6px;height:100%;object-fit:contain;}.ImageContainer_iimwkud svg{height:50px;width:50px;}
.OtherAddresses_o12kr16z{display:grid;grid-template-columns:repeat(4,1fr);row-gap:24px;max-height:230px;overflow-y:auto;}
.OrgDetails2_o12ihbzz.Section_s158zp9i{display:grid;grid-template-columns:repeat(2,1fr);max-height:335px;overflow-y:auto;}
.AddressBlock_a329ire{padding-top:var(--a329ire-0);}
.List_l1cq0bl3{padding-inline-start:16px;margin-top:16px;}
.ListItem_l13ilhu2 + .ListItem_l13ilhu2{margin-top:8px;}.ListItem_l13ilhu2::marker{color:#858996;}
.Identifiers_i19044g6{margin-top:16px;}
.IdentifierListItem_igvo404{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;white-space:pre-wrap;}.IdentifierListItem_igvo404 + .IdentifierListItem_igvo404{margin-top:8px;}
.ListItemSecondaryInfo_l9xboqk{color:#858996;}
.NoneIdentified_ntw1ft9{display:var(--ntw1ft9-0);font-style:italic;color:#b3b5bd;}
.PreviousNamesContainer_phhj423{padding-right:16px;}
.OrganisationType_o1bus8xr{padding-right:8px;}
.RelatedOrgCardsTitle_r1ntxnyq{font-size:1rem;color:#858996;}
.SourcesList_sj3qqzu{margin-top:8px;}
.RegisteredAddress_rdvxd25{border-right:var(--rdvxd25-0);margin-right:var(--rdvxd25-1);}
.masonryGrid_mmbc9p0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:auto;margin-top:12px;max-height:385px;overflow:auto;}.masonryGrid_mmbc9p0 > div:not(:first-child){padding-left:10px;}
.masonryColumn_msxm97e{background-clip:padding-box;}.masonryColumn_msxm97e > div{margin-bottom:14px;}
.sourceLink_srct2gi{display:inline-block;}
