.StageContainer_sqlkhxh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-top:20px;-webkit-transition:opacity 0.2s;transition:opacity 0.2s;pointer-events:var(--sqlkhxh-0);}
.StageHeading_s1tviylv{font-size:2rem;text-align:center;cursor:default;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin-bottom:29px;-webkit-transition:opacity 0.2s;transition:opacity 0.2s;opacity:var(--s1tviylv-0);}
.ContentSwitchContainer_c1rpcec7{position:relative;}.ContentSwitchContainer_c1rpcec7:hover{cursor:var(--c1rpcec7-0);}
.ContentSwitch_civb66l{margin:20px 0;}
.TextInput_t1g3gtkv{margin:20px !important;width:387px !important;}
.TooltipContent_tlhsq3i{text-align:left;padding:8px;overflow-y:auto;width:365px;padding-right:5px;font-size:0.875rem;}
.TooltipHeader_t1nnh7da{border-bottom:1px solid #d2d3d8;padding-bottom:6px;}
.TooltipBody_taz16wk{padding:8px 0;max-height:300px;overflow-y:auto;}
.InfoIcon_i16kpdno{width:20px;height:20px;}.InfoIcon_i16kpdno path{fill:#ffffff;}.InfoIcon_i16kpdno:hover{cursor:pointer;}
.BetaTabContainer_b50mcmz{position:absolute;top:-4px;right:1px;}
