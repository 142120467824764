.NavigationButton_nspdwk8{all:unset;margin-right:10px;padding:0;}.NavigationButton_nspdwk8:focus{outline:none;}
.NavigationButtonsContainer_nzyj7n5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-top:18px;margin-bottom:16px;}.NavigationButtonsContainer_nzyj7n5 svg{width:22px;height:22px;}.NavigationButtonsContainer_nzyj7n5 svg:hover path{fill:#474952;}.NavigationButtonsContainer_nzyj7n5 svg.disabled path{fill:#dfe1e3;}
.OrderButton_ok7tgql{background-color:var(--ok7tgql-0) !important;color:var(--ok7tgql-1) !important;font-size:0.875rem;min-height:22px !important;min-width:50px !important;margin-right:6px;border-color:#dfe1e3 !important;}.OrderButton_ok7tgql:hover,.OrderButton_ok7tgql:focus{box-shadow:0 0 0 0.2rem rgb(0 123 255 / 25%) !important;background-color:#007AFF !important;color:#ffffff !important;border:1px solid #007AFF !important;}
.GalleryViewContainer_gfg3sqi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border-top:1px solid #d2d3d8;}
.GalleryView_gme8z24{max-width:59%;}
.GalleryImages_g12olw2s{overflow-x:auto;position:relative;width:auto;max-height:429px;}.GalleryImages_g12olw2s img{border:2px solid #ffffff;}.GalleryImages_g12olw2s img:hover{border:3px solid #007AFF;}
.GridImage_g13ad5i7{height:100%;width:100%;object-fit:cover;}
.ImageBankContainer_if2naez{font-size:0.875rem;}
.Header_hhxzibx{font-size:0.875rem;color:#858996;}
.OrderButtonsContainer_o50otud{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:18px;margin-bottom:16px;}
.OrderByLabel_o1q9u2kl{margin-right:16px;font-size:0.875rem;color:#858996;}
.FullImage_f1d5w4yb{object-fit:cover;padding-bottom:4px;}
.FullImageCard_f1jkkyu3{font-size:0.875rem;line-height:20px;}
.FullImageCardContainer_f839gc0{padding-left:34px;max-width:38%;}.FullImageCardContainer_f839gc0 a:hover{cursor:pointer;}
.ImageDocumentTitle_i5f2bxz{font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;}
.ImageAltText_i12pm04a{font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#858996;}
.ImageDate_izseg0j{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#858996;}
.ImageOriginUrl_i1tmxn18{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;}
.ImageThirdParty_i11ad8pp{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#dfe1e3;}
.ImageSources_i2nrc7x{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;margin-top:8px;}
.HeaderContainer_hdlq9qt{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-bottom:16px;height:36px;}
.SectionCount_s1bausbp{color:#474952;font-size:2rem;}
.Grid_g1n99ane{display:grid;grid-gap:4px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}
.GridContainer_gr0ygb1{width:588px;}
.columnFirst_ckenvx{grid-template-rows:repeat(3,1fr);grid-auto-flow:column;}
.rowFirst_rmqp13y{grid-template-columns:repeat(4,1fr);grid-auto-flow:rows;}
.twoByTwo_t1jmisws{grid-template:1fr 1fr / 1fr 1fr;}
