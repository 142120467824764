.extra-info-toggler {
  height: 100%;
  min-height: 50px;

  .report-section-content-inner > div {
    justify-content: normal !important;
    height: initial;
  }

  .title-with-flag {
    width: calc(100% - 36px); //36px is flag container width
  }
  .title-without-flag {
    width: calc(100% - 20px);
    margin-left: 20px;
  }

  .primary-information,
  .secondary-information {
    // height: auto!important;
    justify-content: normal;
  }
  .primary-information {
    line-height: $line_height__thin;
    font-size: $font_size_sm;

    &__heading {
      color: $grey_dark;
      font-family: $InterDisplay;
    }
    &__information {
      color: $grey_hover;
      text-transform: capitalize;
    }
  }
  .collapse.show .role-information .secondary-information {
    height: auto;
  }
  .role-information .info-block {
    margin-bottom: 8px;

    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .secondary-information {
    font-size: $font_size_sm;
    font-family: $Inter_SemiBold;
    line-height: $line_height__thin;
    color: $grey_hover;

    &--heading {
      color: $grey_dark;
    }
    &--information {
      color: $grey_hover;

      &--ongoing-role {
        color: $green;
      }

      &--name-change {
        font-family: $Inter_SemiBold;
        color: $blue_mid_darkest;
      }
      &--org-name {
        color: $grey_dark;
      }
      &--ubo {
        font-family: $Inter_SemiBold;
        color: $brown_dark_alpha_06;
      }
    }
    p {
      margin: 0;
      text-transform: capitalize;
    }
  }
  .bottom-border {
    border-bottom: 1px solid $grey_rule;
  }

  .toggle-extra-information {
    flex-direction: initial;
    // top: 10px;

    .toggle-icon {
      &__extra-information {
        width: 20px;
        height: 20px;
        margin-top: 12px;

        &--expanded {
          transform: rotate(90deg);
        }
      }
    }
  }
  .cursor-pointer {
    &:hover {
      cursor: pointer;
    }
  }
}
a.source-link {
  display: block;
  color: $grey_hover;

  &:hover {
    color: $blue_hover;
    svg {
      stroke: $blue_hover;
    }
  }
  &:first-of-type {
    margin-top: 8px;
  }

  .link-icon {
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
}
.company-description {
  margin-top: 16px;
  font-size: $font_size_sm;
  color: $grey_hover;
}
.toggle-extra-information,
.more-items {
  cursor: pointer;
}
