.sub-heading {
  font-size: $font_size_sm;
  font-family: $Inter_Regular;
  color: $grey_hover;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report-section-row .report-section-content-outer .sub-heading {
  color: $grey_dark;
  font-size: $font_size_sm;
  padding-bottom: 8px;
}
