.IconContainer_ih1slqc{position:absolute;left:190px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:36px;height:36px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:30px;background:#ffffff;box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1), 0px 0px 1px 0px rgba(0,0,0,0.25);}
.ThreeDots_t123ysqk{cursor:pointer;height:24px;}.ThreeDots_t123ysqk:hover{color:#02a3d5;}
.ToolBarContainer_thtazol{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;gap:2px;padding:2px;background:#ffffff;border-radius:16px;box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1), 0px 0px 1px 0px rgba(0,0,0,0.2);}.ToolBarContainer_thtazol:after{content:"";position:absolute;top:-10px;right:-10px;bottom:-10px;left:-10px;border:10px solid transparent;z-index:-1;}
.ToolBarWrapper_twl25km{position:absolute;left:-210px;top:-13px;}
.ToolbarButton_t1rpfwbs{padding:6px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;text-align:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;background:#ffffff;border-radius:26px;box-shadow:none;border:none;opacity:var(--t1rpfwbs-0);}.ToolbarButton_t1rpfwbs:focus{outline:none;}.ToolbarButton_t1rpfwbs:hover{background:var(--t1rpfwbs-1);}.ToolbarButton_t1rpfwbs:disabled{opacity:0.3;pointer-events:none;}
.UpArrow_u48dkxt{width:18px;height:18px;}
.DownArrow_djvuikh{width:18px;height:18px;}
.MoveArrow_mly6nuz{width:18px;height:18px;}
.Edit_e1ewp584{width:18px;height:18px;}
.Copy_c3jqa94{width:18px;height:18px;}
.Message_m1vb3egz{width:18px;height:18px;}
.DiscardToolbarButton_d16hj8dc.ToolbarButton_t1rpfwbs:hover{background:var(--d16hj8dc-0);}
.Trash_t3jn0ac{width:18px;height:18px;color:var(--t3jn0ac-0);}
