.LoadingContainer_liyyrws{height:90vh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.ReportScrollContainer_r1b7hfjj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:stretch;-webkit-justify-content:stretch;-ms-flex-pack:stretch;justify-content:stretch;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media screen{.ReportScrollContainer_r1b7hfjj{margin-top:84px;}}
.ReportOuterContainer_rl6wy8y{--border-radius:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-top:0px;padding-bottom:20px;width:100%;max-width:1080px;background-color:#EDEDED;min-height:-webkit-max-content;margin-bottom:35px;border-radius:var(--border-radius);border-top-right-radius:var(--rl6wy8y-0);border-top-left-radius:var(--rl6wy8y-0);}.ReportOuterContainer_rl6wy8y > *{width:100%;}.ReportOuterContainer_rl6wy8y .report-inner-container{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;padding:0 25px;}
.ViewerModeHeader_v1dfchp9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#19a49b;border-top-left-radius:1.25rem;border-top-right-radius:1.25rem;padding-left:1rem;height:3rem;width:100%;max-width:1080px;}
.ViewerModeHeaderTitle_vvkf5jt{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:1.125rem;}
.ViewerModeHeaderLabel_v175ti1h{padding-left:7px;padding-bottom:1px;}
.PlaceholderSection_phec1d6{height:300px;padding-left:10px;}
.Loading_l3jeszc path{fill:var(--l3jeszc-0);}
.InfoContainer_iriulp3{background-color:#ffffff;color:#474952;max-width:1080px;padding-bottom:20px;border-radius:6px;}
.InnerInfoContainer_iv8gbim{padding:25px 5vw;}
.Info_iv5rx1g{text-align:center;margin:32px auto 0;max-width:700px;font-size:0.875rem;}
.CloseModalAlertIcon_c1ujfwd7{height:21px;width:21px;}.CloseModalAlertIcon_c1ujfwd7 circle{stroke:transparent;}.CloseModalAlertIcon_c1ujfwd7 path{fill:#ffffff;}
.CloseModalAlertButton_cknttxh{all:unset;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:background-color 0.3s ease-in-out;transition:background-color 0.3s ease-in-out;}.CloseModalAlertButton_cknttxh:hover,.CloseModalAlertButton_cknttxh:focus{outline:none;background-color:rgba(0,0,0,0.4);}
.Modal_m1qtq5jy{max-width:860px;margin-top:350px;font-size:1rem;}
.ModalBody_m18l8hov{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:12px;background-color:#355672;font-size:0.875rem;padding:24px;border-radius:12px;}
.ActionButtons_a1oqtm1d{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0px;gap:12px;}
.NoTraceRightContent_nn0z0ta{-webkit-flex:1;-ms-flex:1;flex:1;}
.MainContent_mu4tpuz{margin-bottom:24px;}
.NoReportContext_ndqlp8{text-align:left;}
.Header_h15799aa{font-size:1.125rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:1.5;}
.SubHeader_sgb4np5{font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1rem;line-height:1.33333333;margin:16px 0;}
.ExtraGuidanceText_e9oyu3r{margin:0;margin-top:16px;color:#dfe1e3;}
.PrimaryBannerButton_p1jn5q0w{all:unset;border-radius:100px;padding:6px 12px;border:1px solid #ffffff;background-color:#ffffff;color:#0E1A29;}.PrimaryBannerButton_p1jn5q0w:focus,.PrimaryBannerButton_p1jn5q0w:hover{outline:none;box-shadow:0 0 0 4px rgba(40,239,250,0.33);}.PrimaryBannerButton_p1jn5q0w:focus-visible{outline:2px solid blue;}
.SecondaryBannerButton_s1up5koe{all:unset;padding:6px 12px;border-radius:100px;border:1px solid #ffffff;}.SecondaryBannerButton_s1up5koe:focus,.SecondaryBannerButton_s1up5koe:hover{outline:none;box-shadow:0 0 0 4px rgba(40,239,250,0.33);}.SecondaryBannerButton_s1up5koe:focus-visible{outline:2px solid blue;}
.modalContent_mz12u58{border-radius:12px;border:none;}
