.date-picker-input {
  width: 6rem;
  padding: 2px 5px 1px 5px;
}
.date-range-container {
  display: flex;
  align-items: baseline;
  padding-top: 3px;
  padding-bottom: 3px;
}
.date-range-start,
.date-range-end {
  display: flex;
  align-items: baseline;
}
.date-range-start {
  flex-grow: 1;
}
.date-range-label {
  margin-right: 10px;
}
