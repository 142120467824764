.report-controls {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  overflow: none;
  position: relative;

  .report-section-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    max-width: 1080px;

    .menu {
      background-color: $white;
      padding: 0;
      height: auto;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-in;
      z-index: $z-index-top;
      position: absolute;
      top: 30px;
      font-family: $Inter_Regular;
      right: -20px;

      &--expanded {
        max-height: 1000px;
      }

      ul {
        padding: 4px;
        margin: 0;
        background-color: $white;
        li {
          list-style-type: none;
          cursor: pointer;
          padding: 8px 5px 8px 5px;
          background-color: $grey_panel;
          border-radius: 3px;
          margin-bottom: 8px;
          width: 253px;
          $color: $grey_dark;
          font-family: $Inter_SemiBold;
          font-size: $font_size_sm;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-flow: row wrap;

          &:hover,
          a:hover {
            background-color: $blue_hover;
            color: $white;
          }
          .title,
          .item-count {
            display: flex;
            align-content: center;
          }
          .title {
            align-items: flex-start;
          }
          .item-count {
            align-items: flex-end;
          }
        }
      }

      img {
        width: 20px;
      }
    }
  }
}
