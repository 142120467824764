.subject-section {
  .report-section-content-outer {
    .report-section-content-inner {
      .report-table-header {
        margin-top: 0;
      }
    }
  }
  .report-section-row
    .report-section-content-outer
    .report-table-row
    .report-table-header {
    margin-top: 0;
  }
}

.small-section-count {
  font-size: $font_size_xl;
  color: $grey_rule;
  display: inline-block;
  margin-top: 2px;
}
