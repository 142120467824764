.diagnostics-timings-tree {
  width: 100%;
  height: 100%;
}
.diagnostics-timings-tree-control-bar {
  width: 100%;
}
.responsive-svg-container {
  /* display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  overflow: hidden; */
  overflow: scroll;
}

/* .responsive-svg {
  display: inline-block;
} */
