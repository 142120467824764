.confirm-job-submission-content .heading,
.heading {
  font-size: $font_size_xl;
  color: $grey_dark;
}
.confirm-job-submission-content .heading img,
.heading img {
  width: 26px;
  height: 26px;
}
