.DropdownHeader_d1rme9dz{height:32px;background-color:#7439B3;border-top-left-radius:11px;border-top-right-radius:11px;color:#ffffff;padding:0 16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-bottom:none;font-size:1rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.DropdownFooter_d1iatig2{border-top:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:5px;padding:8px 16px 16px 16px;}
.FooterInfoText_fcxf87k{font-size:0.875rem;color:#858996;padding-bottom:2px;}
.DropdownBody_d486rhi{padding:16px;font-size:0.875rem;color:#474952;white-space:normal;}
.ConfirmButton_cscx9xd{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;gap:0.25rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;color:var(--cscx9xd-0) !important;font-size:0.875rem;min-height:28px !important;background-color:#7439B3 !important;border:0 !important;}.ConfirmButton_cscx9xd:hover,.ConfirmButton_cscx9xd:focus{border:0 !important;box-shadow:none !important;}
.CancelButton_c1awye85{color:var(--c1awye85-0) !important;min-height:28px !important;font-size:0.875rem;background:#EDEDED !important;margin-right:8px;}.CancelButton_c1awye85:hover,.CancelButton_c1awye85:focus{box-shadow:none !important;}
.DropdownContainer_d1zo81a{width:426px;}
.DropdownSubtext_d1w1qz7k{background-color:#EBE5F2;padding:12px 14px;color:#474952;font-size:0.875rem;}
.Tooltip_t1lisv5q{display:inline-block;z-index:100;}
.TooltipContent_tzspmt7{font-size:0.875rem;color:#474952;padding:14px;}
.ActionsContainer_a1oq3h3o{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
