.smallList {
  color: $grey_hover;
  font-size: $font_size_sm;
  text-align: left;
}

.above-define-padding-div {
  flex-grow: 1;
  max-height: 15%;
}

.define-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  position: relative;
  flex-grow: 1;
}
.form-control {
  font-family: $Inter_Light;
}

.define-input {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  display: flex;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
}

.define-label {
  color: $white;
  font-size: $font_size_2xl;
  text-align: center;
  cursor: default;
  font-family: $Inter_Light;
}

.search-boxes-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-boxes {
  padding: 0.1rem;
  margin-top: 0.4em;
  width: 22.5rem;

  > a {
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 25rem;
  }

  &-bottom-line {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.define-context {
  border-radius: 5px;
}

.define__context-row-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
