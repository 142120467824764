.data-tip {
  display: inline-block;
  position: relative;

  &__title {
    cursor: default;
    &:hover {
      cursor: pointer;
      color: $purple_mid;

      * {
        color: $purple_mid !important;
      }
    }
  }
}

.data-tip-container {
  -webkit-column-break-inside: avoid;
  display: inline-block !important;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 450px;
  z-index: 10;
  box-shadow: 2px 2px 2px $grey_hover;
  -webkit-box-shadow: 2px 2px 2px $grey_hover;
  -moz-box-shadow: 2px 2px 2px $grey_hover;

  &--header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey_light;
    padding: 10px;
    font-size: $font_size_sm;

    .left-info {
      width: 100%;
      margin-right: 10px;
    }

    .close-button:hover {
      cursor: pointer;
    }
  }

  &--content {
    width: 100%;
    padding: 0 0 10px 10px;
    font-size: $font_size_sm;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: $InterDisplay;

    a:hover {
      color: $purple_mid !important;
      cursor: pointer;

      svg {
        stroke: $purple_mid !important;
      }
    }
  }
  .nlp {
    margin-right: 8px;
    height: 16px;
    width: auto;
  }

  a.source-link {
    display: block;
    color: $grey_dark;
    margin-right: 2px;

    &:hover {
      color: $blue_hover;
      svg {
        stroke: $blue_hover;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }

    .link-icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
}
