.CancelButton_cmy1mdb{background:#EDEDED;padding:12px 16px;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.MoveButton_m1pvcvop{background:#02a3d5;color:#ffffff;padding:12px 24px;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}.MoveButton_m1pvcvop:hover{background:#02a3d5;}
.Actions_a1yw3e17{padding-top:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ContextTextArea_cqt37ql{border:1px solid #b3b5bd;width:100%;height:180px;border-radius:4px;padding:1rem;overflow-y:scroll;border-radius:6px;background:#fbfbfb;box-shadow:0px 0px 1px 0px rgba(0,0,0,0.25) inset;color:#858996;}
.ContentWrapper_cjkj94a{pointer-events:none;}.ContentWrapper_cjkj94a h3{color:#858996;}
.MovedModalContainer_m19xhtsy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:12px;padding:24px 8px 8px 8px;height:496px;height:268px;}
.IconContainer_i1c6fiy6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:8px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border-radius:48px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:var(--i1c6fiy6-0);}
.TickIcon_t11eesc1{color:#7439B3;}
.MovedText_mqjq1xc{font-size:1.125rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:inherit;}
.MovedTextContainer_m16g3xwf{grid-gap:20px;gap:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.MovedSubText_m106zyms{font-size:1rem;}
