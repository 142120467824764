.PrimaryAction_pe9q1pz{color:#6a4394;}
.CloseLearnMoreButton_cwcepob{padding:8px 26px;background:#7439B3;}
.LearnMorePopover_l1djea6s{margin-left:auto;}
.LearnMoreButton_lvvn9x{-webkit-text-decoration:underline;text-decoration:underline;}.LearnMoreButton_lvvn9x:focus,.LearnMoreButton_lvvn9x:hover,.LearnMoreButton_lvvn9x:active{background:transparent !important;}
.LearnMoreContainer_l1jcn2th{padding:1rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;}

.Footer_f1hd6qcg{position:-webkit-sticky;position:sticky;top:54px;margin-left:-540px;left:50%;max-width:1080px;z-index:8;color:#ffffff;box-shadow:0px 4px 4px 0px #00000040;background:#7439B3;padding:10px 25px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:25px;}
.Title_t1hob0kc{font-size:1.125rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}.Title_t1hob0kc span{opacity:0.7;}
.Actions_a15i0ow4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.TitleMetaInfo_t1f177l4{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.IconContainer_i1ur4jgl{width:40px;height:40px;}
