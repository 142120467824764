.di-software-info {
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  color: $white;
  padding: 40px 10px;

  > * {
    padding: 0.2rem;
  }
}
