.Panel_pahmsuu{position:relative;width:100%;background-color:#ffffff;page-break-inside:avoid;padding:16px;min-height:30px;}
.SectionHeader_swg9lo6{min-height:36px;padding-bottom:16px;border-bottom:1px solid #d2d3d8;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.FilterHeader_ffhv3vh{color:#858996;font-size:0.875rem;padding-right:15px;}
.ResultsSection_r1chsuw{max-height:var(--r1chsuw-0);overflow-y:auto;background-color:#ffffff;}
.SectionTitle_s1axei3w{font-size:1.125rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:inherit;margin:0;padding-right:10px;}
.SectionTotal_spi0ars{font-weight:inherit;margin:0;}
.ActiveFiltersContainer_atrj7gr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;min-height:32px;}
.ActiveFilterPillsContainer_a1n7gdw3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-height:32px;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.ActiveFilterPill_a65a83f{font-size:0.875rem;background-color:var(--a65a83f-0);border-radius:17px;color:#ffffff;padding:1px 7px 1px 11px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;text-transform:capitalize;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-left:10px;}
.ActiveFilterPillLabel_acdw9up{padding-right:5px;font-size:0.875rem;}
.ActiveFiltersLabel_auc4f6n{color:#858996;font-size:0.875rem;margin:auto 0;min-width:90px;}
.CrossIcon_c1n5gl6o{height:20px;width:20px;}.CrossIcon_c1n5gl6o circle{stroke:transparent;}.CrossIcon_c1n5gl6o:hover{cursor:pointer;}
.ResetFiltersButton_rnaw078{min-width:50px;color:#858996 !important;margin-left:10px;}.ResetFiltersButton_rnaw078:hover{border-bottom:1px solid #d2d3d8 !important;}
.ShowDetailButtonContainer_s19zs6r9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:10px;}
.DetailButtonDividerLine_d1ow4dyo{height:1px;background-color:#d2d3d8;-webkit-box-flex:0.5;-webkit-flex-grow:0.5;-ms-flex-positive:0.5;flex-grow:0.5;}
.ShowAdditionalFiltersButton_se5jp6k{color:#858996 !important;padding:0 14px !important;min-height:27px !important;min-width:110px;background-color:transparent !important;font-size:0.875rem !important;margin:0 12px;border:1px solid #d2d3d8 !important;}.ShowAdditionalFiltersButton_se5jp6k:hover,.ShowAdditionalFiltersButton_se5jp6k:focus{box-shadow:0 0 0 4px rgba(210,211,216,0.33) !important;}
.TopSectionContainer_t44yifm{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;padding-top:16px;}
.LeftPaneContainer_lmironu{-webkit-flex:1;-ms-flex:1;flex:1;padding-right:25px;}
.RightSectionContainer_rbr3cga{margin-bottom:var(--rbr3cga-0);-webkit-flex:1;-ms-flex:1;flex:1;padding-left:25px;}
.TotalMediaCount_td28uw1{font-size:1.5rem;text-align:right;}
.RiskCategoryTitle_ryt0g5r{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;padding-bottom:10px;}
.CategoriesScrollContainer_c8u8yhp{max-height:310px;overflow-y:auto;overflow-x:hidden;}
.RiskCategoryContainer_rh93je9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-flow:wrap;-ms-flex-flow:wrap;flex-flow:wrap;padding:0 4px;gap:6px;}
.RiskCategoryPill_rm0020s{padding:0px 8px;border:1px solid var(--rm0020s-0);border-radius:16px;margin-right:6px;-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;font-size:0.875rem;margin-bottom:6px;text-transform:capitalize;background-color:var(--rm0020s-1);color:var(--rm0020s-2);}.RiskCategoryPill_rm0020s:hover{box-shadow:0 0 0 4px rgba( var(--rm0020s-3), 0.25 );cursor:var(--rm0020s-4);}
.SourceFilterPill_s1py5t2k{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.SourceFilterPillLabelContainer_s10zp5mx{padding:0 3px 0 3px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SourceFilterPillLabelContainer_s10zp5mx:first-child{padding-left:0;}.SourceFilterPillLabelContainer_s10zp5mx:last-child{padding-right:0;}
.SourceFilterPillLabel_s10nq81e{padding-right:var(--s10nq81e-0);}
.CustomStickyExpandButton_c8qyvtg{background-color:#ffffff;}
.RiskCategories_reyzpgg{padding-bottom:20px;}
.MediaShowResultsButton_m547hkt{padding-top:18px;padding-bottom:var(--m547hkt-0);}
.NoneIdentified_n1xte07x{color:#b3b5bd;font-size:0.875rem;font-style:italic;}
.RiskCountButton_r13nqy70{background-color:#eaebec !important;font-size:0.875rem;color:#858996 !important;padding:0 10px !important;min-height:21px !important;border:1px solid transparent !important;min-width:10px !important;}.RiskCountButton_r13nqy70:hover,.RiskCountButton_r13nqy70:focus{box-shadow:0 0 0 4px var(--r13nqy70-0) !important;}
.TooltipContent_t1xon5kc{text-align:left;padding:2px;width:var(--t1xon5kc-0);max-width:275px;max-height:325px;overflow-y:auto;}
