.DropdownButton_dgkeg0w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;gap:4px;}
.DropdownButtonText_d11x1iyp:hover{color:#6a4394;}
.Dropdown_d8u1nxw{width:336px;}
.DropdownOption_dbixanq{padding:8px;cursor:pointer;}.DropdownOption_dbixanq:not(:last-child){border-bottom:1px solid #EDEDED;}.DropdownOption_dbixanq:hover{background-color:var(--dbixanq-0);}
.Tooltip_tsx6h8z{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.ControlButton_c1dlm9rq{background:#EDEDED;padding:6px 12px 6px 8px;border-radius:100px;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:var(--c1dlm9rq-0);}.ControlButton_c1dlm9rq h3,.ControlButton_c1dlm9rq p,.ControlButton_c1dlm9rq svg{color:#474952;}.ControlButton_c1dlm9rq:hover{background:var(--c1dlm9rq-1);}.ControlButton_c1dlm9rq:hover span{color:var(--c1dlm9rq-2);}
.ButtonIcon_b13ukdj8{background:var(--b13ukdj8-0);color:var(--b13ukdj8-1);border-radius:50%;min-width:18px;min-height:18px;max-width:18px;max-height:18px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:2px;}.ButtonIcon_b13ukdj8 svg{color:var(--b13ukdj8-2);max-width:100%;max-height:100%;}
.DiscardModalContainer_dqbdxyq{z-index:7;position:absolute;top:0;left:0;width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:rgba(255,255,255,0.7);}
.DiscardedCover_d1h1hzrw{z-index:7;position:absolute;top:0;left:0;width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:rgba(255,255,255,0.7);}
.DiscardedIcon_dievjze{background:#ffffff;border:1px solid #7439B3;color:#7439B3;border-radius:50%;min-width:32px;min-height:32px;max-width:32px;max-height:32px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.DiscardedIcon_dievjze svg{max-width:100%;max-height:100%;}
.DiscardedCoverHeading_d186952y{color:#7439B3;}
.UndoButton_upx2pql{background:#EDEDED;}.UndoButton_upx2pql:hover{background:#d2d3d8;color:#0E1A29;}
.DiscardModalContainerInnerContainer_d1ya5wpw{cursor:auto;position:absolute;top:0;left:0;width:100%;height:100%;background:#ffffff;padding:1rem 2rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
