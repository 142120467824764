.di-tag.location-risk-transparency,
.di-tag.location-risk-eu,
.di-tag.location-risk-treasury {
  background-color: $risk_red;
  display: inline-flex !important;
  margin-right: 4px;
  overflow: inherit !important;
  white-space: inherit !important;
  flex-direction: row !important;

  img {
    margin-right: 4px;
  }
  div {
    line-height: 1.2em;
  }
}

.location-risk-treasury {
  height: 26px;
}

.di-tag.risk,
.di-tag.site-risk,
.di-tag.location-risk {
  background-color: $risk_red;
}
.di-tag.risk.di-tag-not-close-to-subject,
.di-tag.site-risk.di-tag-not-close-to-subject {
  background-color: transparent;
  border: $risk_red solid 1px;
  color: $risk_red;
}
