.LocationCard_lvh53ut{background-color:#EDEDED;border-radius:3px;width:100%;}
.LocationCardContent_ll7oijr{display:grid;grid-template-columns:34% 66%;gap:1rem;padding-top:15px;}
.AddressColumn_a1f2wm92{margin-bottom:0.5rem;}
.AddressContainer_a18rvf0h{margin-bottom:0.5rem;}

.Image_ix06fgo{width:100%;object-fit:cover;height:258px;border-radius:3px;}
.MissingStreetViewImageContainer_mautjp2{text-align:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;color:#858996;font-size:0.875rem;height:97px;background:#dfe1e3;border-radius:3px;padding-top:15px;}.MissingStreetViewImageContainer_mautjp2 svg{width:34px;height:40px;}

.NoStreetviewInfo_nfhefwd{font-size:0.875rem;margin:13px 0;}
.LocationRiskFlagsContainer_l12l1f0z{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;padding:4px 0 3px 0;width:160px;gap:4px;}
