.BarsContainer_bw18k9q{width:100%;font-size:0.875rem;margin-right:5px;opacity:var(--bw18k9q-0);-webkit-transition:opacity 0.2s;transition:opacity 0.2s;height:var(--bw18k9q-1);padding-bottom:6px;}
.BarInfoContainer_b1f1zv3y{padding:5px 4px 0;}
.BarInfo_blersr6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;position:relative;padding-bottom:10px;-webkit-flex-flow:var(--blersr6-0);-ms-flex-flow:var(--blersr6-0);flex-flow:var(--blersr6-0);-webkit-box-pack:var(--blersr6-1);-webkit-justify-content:var(--blersr6-1);-ms-flex-pack:var(--blersr6-1);justify-content:var(--blersr6-1);}
.BarTitle_b64eei6{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;padding-bottom:10px;}
.RemainingBarSegmentContainer_r1fgsh4f{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}
.BarSegment_btyfzzk{margin-right:0;height:10px;background:var(--btyfzzk-0);opacity:var(--btyfzzk-1);-webkit-transition:opacity 0.2s;transition:opacity 0.2s;}.BarSegment_btyfzzk:hover{cursor:var(--btyfzzk-2);}
.BarSegmentLabel_b13nabz6{text-align:left;color:var(--b13nabz6-0);opacity:var(--b13nabz6-1);padding:0px 8px;border:1px solid var(--b13nabz6-0);border-radius:16px;margin-right:6px;margin-bottom:6px;-webkit-transition:box-shadow 0.2s,opacity 0.2s;transition:box-shadow 0.2s,opacity 0.2s;font-size:0.875rem;background-color:var(--b13nabz6-2);color:var(--b13nabz6-3);box-shadow:var(--b13nabz6-4);}.BarSegmentLabel_b13nabz6:hover{box-shadow:0 0 0 4px rgba( var(--b13nabz6-5), 0.25 );cursor:var(--b13nabz6-6);}.BarSegmentLabel_b13nabz6:last-child{margin-right:0;}
.BarSegmentContainer_bmfxizu{width:var(--bmfxizu-0);margin-right:var(--bmfxizu-1);}
.NoBarDataLabel_n1wlsw1c{position:absolute;top:10px;color:var(--n1wlsw1c-0);opacity:0.6;}
.FirstBarLabels_fpkswf4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
