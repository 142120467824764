.styled-card-text-underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 2px; /* only being picked up by FF at the moment, but hopefully one day the other browsers will implement it */
}
.underline- {
  &sourceCardTagSidebarContent {
    text-decoration-color: $sidebarContent_underline;
  }
}
