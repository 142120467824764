.issue-warning {
  margin-bottom: 28px;

  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-family: $Noto_sans; */
    border-radius: 3px;
    background-color: $grey_mid;
    color: $white;
    padding: 5px 16px;
    margin-top: 1px;
    margin-bottom: 1px;
    cursor: default;
    font-size: $font_size_xl;
  }
  &-body {
    display: flex;
    flex-direction: row;
  }
  &-col {
    margin: 1px;
    padding: 8px 16px;
    background-color: $grey_panel;
  }
  &-field-heading {
    color: $grey_mid;
    white-space: nowrap;
  }
  &-subheading {
    color: $grey_mid;
    padding: 8px 16px;
  }
}
