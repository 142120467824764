.lower-define {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > a {
    flex-grow: 0;
  }

  &-outer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.gdprPanel {
  max-width: 35rem;
  font-size: $font_size_sm;
  bottom: -22%;
  right: 25%;
  left: 50%;
  color: $grey_dark;
  display: block;
  padding: 0 45px;
  flex-grow: 0;

  .compile-wrapper {
    margin: 20px;
  }
  .gdpr-text {
    margin: 20px 10px;
    background-color: $white;
    border-radius: 5px;
    padding: 20px;
  }
}

.logoGDPR {
  padding: 8px;
}

.gdpr-text {
  margin: 10px;
}

.compile {
  border-radius: 5px;
  opacity: 0.8;
  cursor: pointer;
  outline: none;

  > img {
    overflow: hidden;
  }
}

.compile-wrapper {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .compileDisabled {
    cursor: default;
  }

  .compileEnabled:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity 500ms;
  }
}
.input-validation-message-container {
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  flex-grow: 1;
}
.inner-input-validation-message-container {
  display: flex;
  flex-direction: row;
}
.input-validation-message-side-padding-div {
  flex-basis: 100%;
  flex-shrink: 1;
}
.input-validation-message {
  flex-basis: auto;
  color: $purple_dark;
  font-size: $font_size_sm;
  background-color: $white;
  align-self: center;
  margin-top: 10px;
  padding: 8px 10px;
  border-radius: 2px;
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease 0.3s;
  -moz-animation: fadeIn ease 0.3s;
  -o-animation: fadeIn ease 0.3s;
  -ms-animation: fadeIn ease 0.3s;
}

.define-tip {
  color: $white;
  margin-bottom: 60px;
  margin-top: 90px;
}

.lower-define__go-button {
  border-radius: 6px !important;
  min-width: 50px !important;
}
