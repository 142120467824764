.full-text-container {
  position: relative;
  text-align: left;

  .full-text {
    display: none;
    position: absolute;
    bottom: 20px;
    padding: 4px;
    line-height: 1;
    border-radius: 4px;
    background-color: $white;
    height: auto;
    width: auto;
    color: $grey_dark;
    z-index: $z-index-top;
    min-width: 80px;
    -webkit-box-shadow: 5px 5px 5px $grey_hover;
    -moz-box-shadow: 5px 5px 5px $grey_hover;
    box-shadow: 5px 5px 5px $grey_hover;
  }

  &:hover {
    .full-text {
      z-index: 999;
      display: block;
    }
  }
}
.tooltip {
  word-wrap: break-word !important;
  .tooltip-inner {
    max-width: 300px !important;
    border: 0 !important;
  }
}
.tooltip-inner {
  background-color: $white !important;
  color: $grey_dark;
  opacity: 1 !important;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after,
.bs-tooltip-top .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-bottom .arrow::before {
  border-top-color: $white !important;
  border-bottom-color: $white !important;
  background-color: transparent;
}
