.sort-filter-facet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: default;

  &:hover {
    background-color: $grey_light;
  }

  &-faded {
    opacity: 0.5;
  }
  &-label-capitalize {
    text-transform: capitalize;
  }
  &-label-italics {
    font-style: italic;
  }
  &-label {
    display: flex;
    align-items: center;

    > * {
      margin: 2px;
      padding-left: 0;
    }
    input {
      margin-right: 5px;
    }
  }
  &-expand-arrow {
    transition-duration: 0.2s;
    transition-property: transform;

    &-expanded {
      transform: rotate(90deg);
    }
  }
}

.sort-filter-facet-snapshot {
  transition: background-color 0.2s, color 0.2s;
  border-radius: 15px;

  &:hover {
    background-color: rgba(0, 168, 229, 0.15);
    color: $blue_icon;
    cursor: pointer;
  }
}
