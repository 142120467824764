.status-cell {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin-left: 0;
  margin-right: 0;

  h2 {
    font-size: $font_size_sm;
    padding: 0;
    margin: 0;
  }
  .status-cell-table-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: $font_size_sm;

    div {
      margin: 0;
      padding: 0;
    }
  }
}
