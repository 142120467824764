.diagnostics-overview-outage-message {
  color: $white;
  margin: 6px 0px;
}

.report-status-summary {
  max-height: 330px !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: -10px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-right: 0;
  }

  div {
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 0px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  .left-column {
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-right: 0px;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .right-column {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .inner-column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0;

      &:first-child {
        margin-right: 10px;

        @media (max-width: 768px) {
          margin-right: 0;
        }
      }
      &:last-child {
        margin-right: 0px;
        margin-left: 10px;

        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
    }
  }

  .status-text-wrapper {
    overflow: auto;
    flex-grow: 1;
    background-color: rgba(255, 255, 255, 0.1);
    margin-right: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-left: 6px;
  }

  .status-text {
    background-color: transparent !important;
    color: $white;
    text-align: left;
    font-size: $font_size_sm;
    padding: 0;
    margin: 0;
  }

  textarea {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    margin: 0;
    background-color: transparent;
    border: none;
    color: $white;
    padding: 6px;
    font-size: $font_size_sm;

    &:focus {
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
    }
  }

  .current-status {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    color: $white;
    padding: 6px;

    .current-status-left {
      text-align: left;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      p {
        padding: 0;
        margin: 0;
      }
    }

    .current-status-right {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin: 0;
      padding: 0;

      p {
        padding: 0;
        margin: 0;
      }
    }
  }
}
