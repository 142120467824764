.Container_chq8s6m{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:24px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;gap:24px;background-color:#ffffff;border-radius:16px;border:1px solid #ffffff;box-shadow:0px 0px 18px 0px rgba(0,0,0,0.25);width:440px;}
.Header_h1xga86o{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;gap:12px;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;}
.HeaderIcon_h1csb2lf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:10px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:50%;background:#EDEDED;}


.TitleContainer_t14i8uhp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:2px;-webkit-flex:1;-ms-flex:1;flex:1;}
.HeaderTitle_h1xkrwnm{color:#000000;font-size:1.25rem;line-height:1.16666667;}
.HeaderTagline_heywc2c{color:#7439B3;font-size:0.75rem;}
.HeaderSubtitle_hgw41vc{color:#858996;font-size:0.75rem;}
.DataContainer_d17onnt9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:8px;}
.DataTitle_d15yuqc3{color:#000000;font-size:0.875rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:1.33333333;}
.DataItems_d1nw20ul{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:2px;color:#858996;font-size:0.875rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:1.33333333;}
