.locations-wrapper {
  font-size: $font_size_sm;
  width: auto;

  .map-warning {
    position: absolute;
    bottom: 10px;
    background: $white;
    width: 61%;
    border-radius: 16px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0 16px;
  }

  .location-expanded-row {
    background: $gray_lighter;
    min-height: 830px;
  }

  .box-shadow {
    background: $grey_panel;
    border-bottom: 1px solid white;
    box-shadow: 12px 0 4px -2px $grey_rule;
    -webkit-box-shadow: 12px 0 4px -2px $grey_rule;
    -moz-box-shadow: 12px 0 4px -2px $grey_rule;
    transition: 0.3s;
    z-index: 1;
  }

  .images-wrapper {
    height: 184px;
    max-height: 184px;
    max-width: 480px;
    overflow-x: auto;
    position: relative;
    width: auto;

    &--full-location {
      max-height: 923px;
      max-width: 100% !important;
    }

    .full-location-grid-item {
      width: calc(100% - 11px);
    }

    &::-webkit-scrollbar:horizontal {
      margin-top: 11px;
      height: 11px;
    }
  }
  .location-bar-wrapper {
    font-size: $font_size_sm;
    align-items: center;
    padding-bottom: 1px;

    &:hover {
      background: rgba(0, 168, 229, 0.15);
      cursor: hand;
    }

    .label-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 4px;
      width: calc(50% - 15px);

      &--child {
        width: 50%;
        padding-left: 35px;
      }
    }

    .country-bar-wrapper {
      display: flex;
      border-radius: 2px;
    }

    .country-bar {
      width: 100%;
      background-color: $grey_mid;
      margin: 4px 0px;
      font-size: $font_size_2xs;
      padding: 0 4px;
      color: $white;
      margin-right: 1px;
    }
    .country-bar-selected {
      background: $blue_icon;
    }
    .country-bar-inactive {
      background: $grey_rule;
    }

    .country-bar-left-border-radius {
      border-radius: 4px 0px 0px 4px;
    }
    .country-bar-right-border-radius {
      border-radius: 0px 4px 4px 0px;
    }
    .country-bar-border-radius {
      border-radius: 4px;
    }

    .risk-country-bar {
      background: $country_risk_warning;
    }

    input[type="checkbox"] {
      visibility: hidden;
    }
    input[type="checkbox"]:checked + label:before {
      border: 1px solid $blue_icon;
    }
    input[type="checkbox"]:hover + label:before {
      border: 1px solid $blue_icon;
    }
    input[type="checkbox"]:checked + label {
      color: $blue_icon;
    }

    label.disabled {
      color: $grey_rule;

      &:before {
        border: 1px solid $grey_rule;
      }
    }

    label.partial-selected {
      color: $blue_icon;

      &:before {
        border: 1px solid $blue_icon;
      }
    }
    label.fully-selected:before {
      background: $blue_icon;
    }

    input[type="checkbox"]:hover + label.disabled:before {
      border: 1px solid $grey_rule;
      cursor: not-allowed;
    }

    label {
      position: relative;
      color: $grey_mid;
      margin-bottom: 0;
      max-width: 90%;
    }
    label:before {
      display: inline-block;
      content: "";
      position: absolute;
      left: -15px;
      top: 5px;
      width: 11px;
      height: 11px;
      background: transparent;
      border: 1px solid $grey_mid;
      border-radius: 48%;
    }

    .faded-button {
      opacity: 0.5;
    }

    .toggle-icon {
      width: 16px;
      height: 10px;

      &--expanded {
        transform: rotate(90deg);
      }
    }
    button {
      border: 0;
      background: transparent;
      padding: 0 !important;
      line-height: 1;
      font-size: $font_size_2xs;
    }
  }
  .col-border-right {
    border-right: 1px solid white;
  }

  .col-border-bottom {
    border-bottom: 1px solid white;
  }

  .grid-item-image {
    width: 100%;
    object-fit: cover;
    height: 258px;
  }

  .border-image {
    &:hover {
      border: 2px solid $blue_hover;
      cursor: pointer;
    }
  }

  .disable-image {
    opacity: 0.1;

    &:hover {
      cursor: not-allowed;
    }
  }
  .show-all-images {
    margin-top: 8px;
    background: $grey_mid;
    color: white;
    border-radius: 2px;
    font-size: $font_size_sm;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }
  .panel-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tag-container {
    display: flex;
    align-items: center;
  }

  .toggle-display-filter {
    width: 34px;

    .toggle-button-wrapper {
      padding: 16px 0 0 10px;
      top: 86px;
      z-index: 6;
    }

    .toggle-display-button {
      display: flex;
      width: 14px;
      height: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .filter-expanded-section {
    width: calc(100% - 34px);
    overflow: hidden;
  }
  .vertical-text {
    margin-top: 32px;
    margin-left: 8px;
    transform: rotate(-90deg);
    font-size: $font_size_sm;
    color: $grey_mid;
  }

  .sticky-filter-header {
    top: 86px;
    z-index: 6;
    padding-bottom: 16px;
    padding-top: 16px;
    background: $grey_panel;
  }
  .address-variant-toggle {
    color: $grey_mid;

    &:hover {
      color: $grey_dark;
      cursor: pointer;
    }
  }

  .unresolved-address {
    color: $brown_dark;
    font-size: $font_size_sm;
  }

  .roles-wrapper {
    border-bottom: 1px solid $grey_rule;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }

    &:first-child {
      padding-top: 0;
    }

    .role-row {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: $purple_mid;
      }
    }
  }

  .risk_images {
    height: 15px;
    margin-right: 4px;
    path {
      fill: $country_risk_warning;
    }
  }
  .active-filter-label {
    color: $blue_icon;
  }
  .filter-reset {
    border: none;
    background-color: transparent;
    color: $grey_mid;
    padding: 0 !important;
    margin-bottom: 10px;
  }
  .info-icon {
    width: 12px;
    height: 12px;
    align-self: center;
    display: flex;

    &:hover {
      cursor: pointer;
      path {
        fill: $purple_mid;
      }
    }
  }
  .cursor-pointer {
    &:hover {
      cursor: pointer;
    }
  }
}

.clickable-tag {
  display: inline-flex;
  background: $blue_icon;
  border-radius: 11px;
  border: 1px solid $blue_icon;
  color: $white;
  font-size: $font_size_sm;
  text-align: center;
  padding-right: 10px;
  margin-right: 8px;

  .close {
    color: white;
    opacity: 1;
  }
  button {
    padding: 0px 10px;
  }
}

.tab-button {
  display: inline-flex;
  background: inherit;
  color: $grey_dark;
  font-size: $font_size_sm;
  text-align: center;
  margin-right: 8px;

  &:hover {
    cursor: pointer;
    color: $blue_icon;
  }

  &--active {
    color: $blue_icon;
    border-bottom: 1px solid $blue_icon;
  }
}

.w-0 {
  width: 0% !important;
}

.w-40 {
  width: 40% !important;
}
.w-10 {
  width: 10% !important;
}
.flex-1 {
  flex: 1;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.original-addresses {
  display: flex;
  flex-wrap: wrap;
}

.filter-panel-closed {
  width: 34px;
}

.address-tooltip {
  text-align: left;
}

.locations__text-button {
  color: $grey_default;
  border: none;
  background: none;
  padding: 0;
  border-bottom: 1px solid;
  border-color: transparent;
  font-size: $font_size_sm;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    border-color: $grey_default;

    &:focus {
      border-color: $grey_dark;
    }
  }

  &:focus {
    outline: none;
    color: $grey_dark;
  }
}

.locations__show-more {
  padding-top: 10px;
}

.locations__reset-filters {
  margin-bottom: 10px;
}

.locations__risk-flags-container {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 4px 0 3px 0;
  width: 160px;

  .di-tag {
    margin: 0 5px 5px 0;
  }
}

.locations__street-view-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.locations__roles-list {
  max-height: 243px;
  overflow: auto;

  & > div {
    max-width: 274px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.locations__location-card-data {
  display: flex;
  padding-top: 15px;
  border-bottom: 2px solid $grey_rule;
  margin-bottom: 15px;
}

.locations__location-no-streetview-text {
  color: $brown_dark;
  font-size: $font_size_sm;
  margin: 8px 0;
}

.locations__role-item {
  display: flex;
  align-items: center;
  & > span {
    padding-right: 5px;
  }
}

.locations__role-year {
  color: $grey_mid;
}

.locations__role-links--button {
  background: none;
  border: none;
  padding: 0;
  font-size: $font_size_sm;
  color: $grey_dark;

  &:hover {
    color: $purple_mid;
  }

  &:focus {
    outline: none;
    color: $purple_mid;
  }
}

.locations__role-orgname {
  max-width: 217px;
}
.locations__role-orgname-fullwidth {
  max-width: 255px;
}

.locations__info-icon {
  height: 15px;

  &:hover {
    cursor: pointer;
    path {
      fill: $purple_mid;
    }
  }
}

.locations__original-address-container {
  text-align: left;
  padding: 16px;
  width: auto;
  min-width: 200px;
  font-size: $font_size_sm;
  font-family: $Inter_Regular;
  -webkit-font-smoothing: auto;
}
.locations__original-address-header {
  padding-bottom: 8px;
  border-bottom: 1px solid $grey_rule;

  h2 {
    font-family: $Inter_SemiBold;
    font-size: $font_size_sm;
    margin: 0;
  }
}
.locations__original-address-content {
  padding-top: 8px;
}

.locations__filter-button {
  padding: 0px 10px !important;
  font-size: $font_size_sm;
  border: 1px solid $grey_rule;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: $black;
  }
}

.locations__location-list {
  background: $grey_panel;
}

.locations__address-row {
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.locations__sort-filter-pill {
  border-radius: 100px;
  border-color: $grey_rule;
  color: $grey_dark;
  background-color: $white;
  outline: none;
}

.location-bar__expand-country {
  outline: none !important;
}

.locations__original-address-date {
  padding-bottom: 8px;
}

.locations__loading-spinner {
  color: $green_xapien; /* TODO - move over to linaria styled components */
  width: 50px;
  height: 50px;
}

.locations__map-container {
  width: 479px;
  height: 241px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.locations__placeholder-street-view-image {
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey_default;
  border: 1px solid $grey_light;
  font-size: $font_size_sm;

  svg {
    width: 34px;
    height: 40px;
  }
}

.locations__placeholder-large-street-view-image {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey_default;
  font-size: $font_size_sm;
  height: 258px;
  background: $grey_light;

  svg {
    width: 34px;
    height: 40px;
  }
}

.locations-bar-wrapper {
  max-height: 300px;
  overflow: auto;
  min-width: 460px;
}
