.Heading_h1f54mzi{font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1.5rem;font-weight:inherit;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;line-height:1.33333333;margin:0;margin-bottom:12px;padding:14px 24px 0;padding:var(--h1f54mzi-0);line-height:1.5rem;}
.Subheading_sxhq1q0{font-size:1.125rem;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:inherit;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;line-height:1.33333333;padding:var(--sxhq1q0-0);}
.HeadingDivider_h1cnbf5c{margin:12px 0 36px 0;}
.LineContainer_l13hux7p{position:absolute;width:1px;background-color:#d2d3d8;height:100%;left:calc(50% - 0.5px);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.LineTerminus_lmbvjpg{height:1px;width:10px;background-color:#d2d3d8;}
.LineTopCover_l1cpmk4l{height:13px;width:1px;background-color:#EDEDED;}
.Line_l5cypx2{width:1px;height:100%;background-color:#d2d3d8;}
.TimelineContainer_t1ynah6{position:relative;width:100%;overflow:hidden;padding:0 24px;}.TimelineContainer_t1ynah6 ul{padding:0;margin:0;}

.ListItem_l1ut0lve{position:relative;clear:both;width:50%;margin-left:var(--l1ut0lve-0) !important;}.ListItem_l1ut0lve:last-of-type{margin-bottom:20px;}
.ClearingElement_crik44b{clear:both;}
.SectionContent_s3ozbif{width:100%;padding:10px 0;overflow-y:var(--s3ozbif-0);-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
