.source-inspector {
  display: inline-block;

  &__content {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
    }

    .article-row {
      justify-content: space-between;
      border-bottom: 1px solid $grey_light;
      padding: 10px 10px 10px 1px;

      a {
        font-family: $Inter_Light;
      }

      .article-title {
        color: $grey_dark;
        font-family: $InterDisplay;
      }

      .square-image {
        width: 60px;
        height: 60px;
      }

      .publisher-wrapper {
        text-align: left;
        &__width {
          &--long {
            max-width: 350px;
          }
          &--short {
            max-width: 200px;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: $InterDisplay;

    a:hover {
      color: $purple_mid !important;
      cursor: pointer;

      svg {
        stroke: $purple_mid !important;
      }
    }
  }

  .block-with-text {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 3.6em;
    margin-right: -1em;
    padding-right: 1em;
  }

  .block-with-text:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .block-with-text:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }

  a.source-link {
    display: block;
    color: $grey_dark;
    margin-right: 2px;

    &:hover {
      color: $blue_hover;
      svg {
        stroke: $blue_hover;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }

    .link-icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
}
