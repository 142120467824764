.TitleCardContainer_tfif1l4{border:1px solid #f14b4b;border-radius:0.75rem;}
.MainContent_mnv18j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.HandleReportsOptions_h19i04m6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;padding:1rem 0;border-top:1px solid #858996;border-bottom:1px solid #858996;}
.RadioGroup_rpwygqu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.1875rem;}.RadioGroup_rpwygqu > label{margin:0;}.RadioGroup_rpwygqu > input{margin-top:1px;border-color:#28effa;}.RadioGroup_rpwygqu > input::before{background-color:#28effa;}
.TransferReports_tww00x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.DeleteReports_d1bgjta9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0.75rem;}.DeleteReports_d1bgjta9 > label{margin:0;}
.FooterExplainer_f11ygexy{margin:0;font-size:0.875rem;color:#b3b5bd;}
.Actions_a1hchrba{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;}.Actions_a1hchrba > button{-webkit-flex:1;-ms-flex:1;flex:1;}.Actions_a1hchrba > button:nth-of-type(2):not(:hover,:disabled){background-color:#f45b5b;color:#ffffff;}
.Checkbox_cpgsqqw{width:1.125rem;height:1.125rem;}.Checkbox_cpgsqqw:checked{accent-color:#28effa;}
.WarningText_wp3xcip{margin:0;color:#b3b5bd;font-size:0.875rem;}
