.CardContainer_c1o4f0xl{background:#fafafa;border-radius:4px;min-width:1000px;padding:20px;}
.CardInnerContainer_c8vx1pk{text-align:left;}
.CardHeader_c12wj71k{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;height:auto;gap:8px;}
.SourceImage_s13yzzol{object-fit:cover;height:56px;width:56px;border-radius:8px;}
.PlaceholderIcon_pek7xmo{object-fit:cover;height:56px;width:56px;border-radius:8px;}
.CountryFlag_c1ivu7fr{width:31px;}.CountryFlag_c1ivu7fr img{width:24px;height:16px;margin:0;margin-top:1px;margin-right:5px;border-radius:2px;}
.GridCell_g6j4tmj{padding:16px;border:1px solid #EDEDED;}
.PersonaCardRiskIcons_pdsaedd.GridCell_g6j4tmj{grid-area:risk-icons;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-top:none;padding-bottom:4px;padding-top:4px;border-right:none;}
.CardTextContainer_c8t9hfw{padding-top:14px;margin-top:8px;height:auto;border-top:1px solid #dfe1e3;color:black;font-size:0.875rem;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:var(--c8t9hfw-0);overflow:var(--c8t9hfw-1);text-overflow:var(--c8t9hfw-2);}
.SimpleContainer_smv5rtr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:var(--smv5rtr-0);-webkit-justify-content:var(--smv5rtr-0);-ms-flex-pack:var(--smv5rtr-0);justify-content:var(--smv5rtr-0);height:auto;width:var(--smv5rtr-1);-webkit-flex-direction:var(--smv5rtr-2);-ms-flex-direction:var(--smv5rtr-2);flex-direction:var(--smv5rtr-2);}
.Title_t12lyg31{font-size:1rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;line-height:1.38;margin-right:4px;}
.Label_l1mst425{font-size:0.875rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;line-height:1.38;margin-right:4px;}
.Tooltip_t1hmh03t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.ExpandButton_e2r56s8{color:#1F7DD9;border:0px;-webkit-text-decoration:underline;text-decoration:underline;background-color:transparent;padding:10px 0 0;font-size:0.875rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:3px;}.ExpandButton_e2r56s8:focus{outline:none;}
.ViewReportButton_v18a3xf4{color:#474952;border:1px solid #808080;font-size:0.875rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;border-radius:6px;background-color:#ffffff;padding:3px 5px;font-size:small;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:3px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:auto;}.ViewReportButton_v18a3xf4:focus{outline:none;}
.ChevronDown_ccuqhto{--icon-width-height:1rem;width:var(--icon-width-height);height:var(--icon-width-height);stroke:#1F7DD9;}
.ChevronUp_c1iokcy3{--icon-width-height:1rem;width:var(--icon-width-height);height:var(--icon-width-height);stroke:#1F7DD9;}
.EyeIcon_e5kam5k{width:18px;height:18px;}
.TagsContainer_t1u5linc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:8px;margin-top:16px;}
