.LocationsBarContainer_l1rownum{padding-left:32px;-webkit-flex:1;-ms-flex:1;flex:1;max-height:170px;overflow-y:auto;}
.CollapseArrow_c16bw8dt{width:16px;height:10px;-webkit-transform:var(--c16bw8dt-0);-ms-transform:var(--c16bw8dt-0);transform:var(--c16bw8dt-0);}
.CheckboxAndLabel_chfy94f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:var(--chfy94f-0);}
.Bar_bysf02h{width:100%;background-color:#858996;margin:4px 0px;font-size:0.625rem;padding:0 4px;color:#ffffff;margin-right:1px;}
.SelectedBar_si17gu1.Bar_bysf02h{background-color:var(--si17gu1-0);border-radius:var(--si17gu1-1);width:var(--si17gu1-2);}
.DefaultSelectedBar_d1orw47x.Bar_bysf02h{border-radius:var(--d1orw47x-0);width:var(--d1orw47x-1);background-color:var(--d1orw47x-2);}
.InactiveBar_i9fztrb.Bar_bysf02h{background-color:var(--i9fztrb-0);border-radius:var(--i9fztrb-1);width:var(--i9fztrb-2);}
.CountryBarInnerWrapper_c13ekgja{width:var(--c13ekgja-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border-radius:2px;}
.CountryBarOuterWrapper_cfxb2r2{width:50%;cursor:var(--cfxb2r2-0);text-align:var(--cfxb2r2-1);}
.RiskFlagsContainer_r17fzcw8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-right:4px;}
.Label_l1fl85pw{margin-bottom:0;cursor:var(--l1fl85pw-0);color:var(--l1fl85pw-1) !important;}.Label_l1fl85pw:before{border:var(--l1fl85pw-2) !important;background:var(--l1fl85pw-3) !important;}
.LabelWrapper_lp9plug{cursor:var(--lp9plug-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:var(--lp9plug-1);width:var(--lp9plug-2);}
