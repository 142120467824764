.OrganisationOverviewSection_o1u476py{color:#474952;margin-bottom:20px;}
.OverviewContainer_ob5yfjf{display:grid;grid-template-areas: "org-scale org-profiles org-details-1 org-details-2" "org-summary org-summary org-description org-description";grid-template-columns:1fr 1fr 1fr 1fr;}
.Topbar_t16yqfvi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}
.OverviewCell_o1bt5zyy{background-color:#ffffff;border-left:1px solid #D2D3D8;padding:16px;}.OverviewCell_o1bt5zyy:first-of-type{border-left:none;}
.OrgLogoContainer_otqv5ru{width:128px;padding:16px;background-color:#ffffff;border-left:1px solid #D2D3D8;border-bottom:1px solid #D2D3D8;}
.OrgLogo_o1ufy40j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;border-top:none;padding-top:10px;}
.OrgLogoImageContainer_o19m2a1r{object-fit:contain;height:88px;}
.OrgDetails1Cell_o4maoew.OverviewCell_o1bt5zyy{grid-area:org-details-1;}
.OrgDetails2Cell_o15pqzyi.OverviewCell_o1bt5zyy{grid-area:org-details-2;overflow-y:auto;}
.OrgPresence_o1y8zdo4.OverviewCell_o1bt5zyy{grid-area:org-scale;position:relative;}
.OrgScaleItem_o1tu2m0h{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:9px;}
.EmployeeCardIcon_eh6jqz2{width:30px;height:30px;}.EmployeeCardIcon_eh6jqz2 path{fill:var(--eh6jqz2-0);}
.RelatedOrgsIcon_r1uo3y06{width:30px;height:30px;}.RelatedOrgsIcon_r1uo3y06 path{fill:var(--r1uo3y06-0);}
.GlobeIcon_g1eig7j5{width:30px;height:30px;}.GlobeIcon_g1eig7j5 path{fill:var(--g1eig7j5-0);}
.SocialReachIcon_srvhgol{width:30px;height:30px;}.SocialReachIcon_srvhgol path{fill:var(--srvhgol-0);}
.GraphicSubtext_g1tge0cw{font-size:0.875rem;color:#858996;margin:0;margin-top:2px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.OrgScaleDetail_o4c8fzm{font-size:0.875rem;color:var(--o4c8fzm-0);}.OrgScaleDetail_o4c8fzm span{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:600;padding:0 4px;}
.OrgProfiles_o1jxviqy.OverviewCell_o1bt5zyy{grid-area:org-profiles;overflow-y:auto;padding-right:11px;}
.OrgSummary_o13mn5zp.OverviewCell_o1bt5zyy{grid-area:org-summary;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;position:relative;}.OrgSummary_o13mn5zp.OverviewCell_o1bt5zyy p{font-size:0.875rem;margin:0;}
.OrgSummaryContainer_oe8s5p6{max-height:170px;overflow-y:auto;}
.LinkToSummary_lmi0p4e{color:#858996 !important;font-size:0.875rem !important;min-width:0;border-bottom:1px solid transparent;}.LinkToSummary_lmi0p4e:hover,.LinkToSummary_lmi0p4e:focus{border-color:#858996;}
.OrgDescription_o5jei7b.OverviewCell_o1bt5zyy{grid-area:org-description;}.OrgDescription_o5jei7b.OverviewCell_o1bt5zyy p{font-size:0.875rem;margin-bottom:0;}
.OrgDescriptionContainer_o1wzdaoi{font-size:0.875rem;margin-bottom:0;height:170px;overflow-y:auto;}
.QuotesIcon_q6juu74{float:left;margin:5px 10px 0 0;}.QuotesIcon_q6juu74 path{fill:var(--q6juu74-0);}
.OrgDescriptionText_o1ns6k2s:hover{cursor:var(--o1ns6k2s-0);}
.SectionTitle_s1tfjufp{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;color:#858996;margin:0;}
.OrgScaleTitle_o49cvzk.SectionTitle_s1tfjufp{margin-bottom:4px;}
.OnlineProfilesTitle_or31iis.SectionTitle_s1tfjufp{margin-bottom:4px;}
.OrgDescriptionTitleContainer_owf7e2s{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.OrgDescriptionTitle_o7ksix2{color:#858996;font-size:0.875rem;margin:0;max-width:220px;display:inline;}
.ShowAllButton_s19u8ej{color:#858996 !important;font-size:0.75rem !important;min-width:0;padding-top:8px !important;}.ShowAllButton_s19u8ej:hover,.ShowAllButton_s19u8ej:focus{border-color:#858996 !important;}
.OrgDuration_op3cn5w{color:#858996;}
.HQContainer_h1rb59q7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.JurisdictionContainer_jx3vsdl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.CustomFlag_cxbmxek{width:31px;}.CustomFlag_cxbmxek img{width:24px !important;height:16px !important;margin:0;margin-top:1px !important;margin-right:5px !important;border-radius:2px !important;}
.InformationIcon_ihr6paz{height:20px;width:20px;padding-left:2px;}
.PopoverHomepageImage_p7qesf0{padding-right:6px;}
.ImageLoadingSpinner_i1gipmlk{color:#19a49b;position:absolute;}
.HomepageLink_h12y0ft{color:#858996 !important;font-size:0.875rem;}.HomepageLink_h12y0ft:hover{color:#007AFF !important;}
.OnlineProfilesContainer_oyh0bwj{max-height:140px;overflow-y:auto;padding-right:5px;}
.TooltipContent_ttmz6gq{text-align:left;padding:2px;max-height:325px;overflow-y:auto;width:240px;}
.DescriptionSentenceSeparator_d1wtnq58{white-space:pre;margin:0 2px;}
.DescriptorSentenceContainer_dpwthsx{display:inline;}.DescriptorSentenceContainer_dpwthsx:last-child .DescriptionSentenceSeparator_d1wtnq58{margin:0;}
.DescriptorSources_dutjv99{font-size:0.875rem;color:#858996;}.DescriptorSources_dutjv99 a{color:#858996;}.DescriptorSources_dutjv99 a:hover{color:#474952;}
.DescriptionSourceContainer_dbi38yi{max-width:400px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
.NoDate_n147mjz{color:#858996;}
.RoleDuration_ribqhg9{color:#858996;}
.Info_i2db7ww{font-size:0.875rem;color:#858996;white-space:nowrap;}
.Founders_f1af320o:hover{cursor:pointer;}
.WebsitePlaceholder_wegvk63{max-width:224px;max-height:120px;border-radius:3px;}
.SocialReachTooltipList_s10x1wcb{padding-top:8px;padding-left:25px;margin:0;}
.NoSocialPresenceLabel_n1w6hptm{margin:0;padding-bottom:8px;}
.SummaryText_spdghqk{word-break:break-word;}.SummaryText_spdghqk:hover{cursor:var(--spdghqk-0);}
.PlaceholderText_p14niecj{font-style:italic;color:#b3b5bd;}
.SummaryTooltipContent_s1y5x7bx.TooltipContent_ttmz6gq{width:400px;}
.InfographicContainer_ij5bu7j{position:relative;margin:auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}
.OrgScaleIconContainer_o3vt36f{position:relative;}
.RiskCountIcon_r16u9r41{position:absolute;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;aspect-ratio:1;min-width:21px;bottom:-6px;right:-3px;border-radius:50%;font-size:0.625rem;background-color:#ef7273;line-height:17px;color:#ffffff;border:2px solid #EDEDED;padding:var(--r16u9r41-0);}.RiskCountIcon_r16u9r41:hover{cursor:pointer;}
.RiskTooltipBody_r10z10bz{max-height:200px;overflow-y:auto;padding-top:5px;}
.RiskList_rqqb5w1{margin-bottom:8px;line-height:1.3;font-size:0.875rem;}
.Risks_rpxf6a1{color:#858996;}
.RiskAssignerText_rtyktew{color:#858996;}
.StreetviewImage_spklk18{border-radius:3px;}
.Tooltip_t8fosbg + div{display:inline;}
.FounderPill_f5z62rt{text-align:left;}
.Duration_drrd6jb{color:var(--drrd6jb-0) !important;font-size:0.875rem;}.Duration_drrd6jb .NoDate_n147mjz{color:var(--drrd6jb-1);}
.FounderContainer_f1ho4ayu{padding-bottom:6px;}
.SmallEmployeeIconInfographic_sp8td4e{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SmallEmployeeIconInfographic_sp8td4e .EmployeeCardIcon_eh6jqz2{height:35px;width:35px;padding-bottom:0;}
.SmallSocialReachIconInfographic_sdezcem{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SmallSocialReachIconInfographic_sdezcem .SocialReachIcon_srvhgol{height:35px;width:35px;padding-bottom:0;}
.HomepageProfile_h10a72es{padding:2px 4px;}
.BuildingIcon_b9nu1xg path{fill:#ffffff;}
.BuildingIconContainer_b15rvx36{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#dfe1e3;height:96px;width:96px;border-radius:3px;}.BuildingIconContainer_b15rvx36 > .BuildingIcon_b9nu1xg{-webkit-transform:scale(2);-ms-transform:scale(2);transform:scale(2);}
.MediaIconsContainer_m79su38{padding:var(--m79su38-0);}
.SocialReachInfoIcon_sm3egcz{margin-bottom:3px;}
.BulletPoint_b12a0xp6{height:4px;width:4px;display:inline-block;border-radius:50%;margin-right:8px;background-color:#474952;margin-top:3px;margin-bottom:3px;}
.SourceContainer_s1xnq9uk{max-height:275px;overflow:auto;}
.CountryFlag_cgm7w6{width:30px;}.CountryFlag_cgm7w6 img{width:24px !important;height:18px !important;margin:0;margin-right:8px !important;border-radius:2px !important;}
.Country_cnty560{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.InspectorContent_i69ip5w{max-height:175px;overflow:auto;margin-right:-5px;padding-right:5px;}
.StateOwnedCompany_s1pqg9m1:not(:first-of-type){border-top:1px solid #d2d3d8;margin-top:11px;padding-top:2px;}
.RiskCountry_r1dke4o7:not(:first-of-type){border-top:1px solid #d2d3d8;margin-top:8px;padding-top:8px;}
.CountryName_ciiawbw{font-size:0.875rem;padding-bottom:4px;}
.CompaniesList_c1goveut{padding-left:var(--c1goveut-0);}
.CompanyField_cr0oup2{padding-left:11px;text-indent:-12px;}
.RelatedCountryHeader_rhazzw9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.RiskAssigners_r5c6oaw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-top:8px;gap:8px;}
.CustomSectionFooter_clyqa4j{border-top:1px solid #D2D3D8 !important;}
.ExplainerText_e124idud{font-size:0.875rem;}
.OrgIconContainer_oojaaro{width:36px;height:36px;border-radius:3px;background-color:#19a49b;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:8px;}
.OrgIcon_oi9j864{-webkit-transform:scale(0.65);-ms-transform:scale(0.65);transform:scale(0.65);}
.InfoSummaryRow_i1pa84as:not(:last-of-type){margin-bottom:8px;}.InfoSummaryRow_i1pa84as ul{padding:0 14px;margin:0;}
.SectionLinkButton_shp1lhc{border-bottom:1px solid #858996 !important;color:#858996 !important;font-size:0.875rem !important;line-height:13px;}
.List_l1gqr4p0{margin-top:11px !important;padding-top:14px !important;padding-left:0 !important;border-top:1px solid #d2d3d8;list-style:none;}
.ListItem_l2dxos4{font-size:0.875rem;}.ListItem_l2dxos4 + .ListItem_l2dxos4{margin-top:12px;}
.CompanyLocation_c11zzkvo{color:#858996;}
.RiskIconsSectionTitle_r4shtce.SectionTitle_s1tfjufp{margin-bottom:4px;}
.OrgLogoSectionTitle_onmgdss.SectionTitle_s1tfjufp{margin-bottom:4px;}
.NoneIdentified_nt17ngc{color:#b3b5bd;font-size:0.875rem;font-style:italic;}
.StateOwnedCompanies_s1v4l4uv{overflow:auto;height:100%;}
.CustomSourceList_cg9o1m0{overflow:inherit;}
.IndirectExplainer_i1fzqe8n{padding-top:8px;}
.dataField_d150famd dt{color:#474952;margin:0;}.dataField_d150famd dd{margin-bottom:8px;}
.orgLogoImage_oigod05{border-radius:3px;height:88px;width:94px;object-fit:contain;}
.locationRiskTag_lkah6tm{border-radius:11px;height:23px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:8px;opacity:1 !important;background-color:#ef7273 !important;}.locationRiskTag_lkah6tm > svg{margin-top:0px;margin-right:-3px;}
