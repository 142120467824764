.LocationRolesWrapper_l1gu2bky{border-bottom:1px solid #d2d3d8;padding:10px 0;}.LocationRolesWrapper_l1gu2bky:last-child{border-bottom:none;}.LocationRolesWrapper_l1gu2bky:first-child{padding-top:0;}
.RoleSectionTitle_rljmkih{color:#858996;}
.LocationRoleItem_l195wjay{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.LocationRolesList_l11sfu71{max-height:243px;overflow:auto;}.LocationRolesList_l11sfu71 > div{max-width:274px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.InformationIcon_i1oehtfo{height:15px;margin-left:1px;}.InformationIcon_i1oehtfo:hover{cursor:pointer;}.InformationIcon_i1oehtfo:hover path{fill:#1F7DD9;}
.RoleYear_r1u8doma{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#858996;padding-left:3px;}
.LocationOrgName_l1sudma1{max-width:var(--l1sudma1-0);}
.InfoIcon_iceni7n{margin-bottom:3px;}
.TooltipContainer_t1cxh0l7{background:#ffffff;text-align:left;border-radius:4px;font-size:0.875rem;-webkit-column-break-inside:avoid;}
.Tooltip_tt4au95{margin-top:-8px;height:15px;}
.TooltipHeader_tjesj1t{border-bottom:1px solid #d2d3d8;padding-bottom:6px;}
.TooltipBody_t1ff1zvc{padding-top:6px;}
.AddressTooltip_a1i7vm4w{text-align:left;}
.AddressDate_a178u926{padding-bottom:8px;}
.ShowAllButton_s1xr4d32{color:#858996;border:none;background:none;padding:0;border-bottom:1px solid;border-color:transparent;font-size:0.875rem;padding-top:10px;}.ShowAllButton_s1xr4d32:disabled{cursor:default;pointer-events:none;}.ShowAllButton_s1xr4d32:hover{cursor:pointer;border-color:#858996;}.ShowAllButton_s1xr4d32:hover:focus{border-color:#474952;}.ShowAllButton_s1xr4d32:focus{outline:none;color:#474952;}
.infoIconTooltipContent_i167ptzx{width:auto;}
