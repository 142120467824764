.di-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: solid $white 1px;
  min-height: 41px;
  padding-top: 4px;
  padding-bottom: 8px;
}

.di-source-card {
  hr {
    padding: 0;
    margin: 0.5rem 8px 0;
  }

  .di-card-footer {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.di-card-footer-start,
.di-card-footer-end {
  min-width: 150px;
}

.di-card-footer-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.di-card-translate-control {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.di-card-translate-control-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.di-card-language {
  all: unset;
  text-transform: uppercase;
  cursor: pointer;
  padding-top: 0.15rem;
}

.di-card-language-active {
  color: $blue_icon;
}

.di-card-translate-toggle-button {
  opacity: 0.7;
  outline: none;
  border: none;
  background: none;

  &:focus {
    outline: none;
    border: none;
  }
  &:hover {
    opacity: 1;
  }

  img {
    min-width: 30px;
  }
}

.di-source-card-emphasis {
  border-color: $grey_mid;
}

.di-card-stack-expander {
  font-size: $font_size_sm;
  color: $blue_icon;
  cursor: pointer;
}

.di-card-stack-expander-image-button {
  all: unset;
}
