.source-section,
.source-group-contents,
.source-stack-expanded-contents {
  display: grid;
  grid-template-columns: repeat(auto-fill, 509px);
  grid-gap: 5px;
}
.source-group {
  grid-column: 1/-1;

  &-summary {
    padding: 15px;
    display: flex;
    justify-content: stretch;

    &-summary-image,
    &-summary-image > img {
      max-width: 180px;
      max-height: 120px;
    }
  }
  &-summary-snippet {
    flex-grow: 1;
    padding: 0.3rem;
  }
  &-contents {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &-end {
    border-top: 3px solid $grey_panel;
    margin-top: 15px;
    margin-bottom: 12px;
  }
}

.media-group {
  grid-template-columns: repeat(auto-fill, 480px);
}
