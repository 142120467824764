.tag-inspection-panel {
  padding: 10px;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  transform: translateY(100%);
  mask-image: linear-gradient(to bottom, $black 95%, transparent 100%);

  &:not(.tag-inspection-panel-hidden) {
    animation: inspection-panel-slide-up 0.3s forwards;
  }
  &-hidden {
    animation-name: inspection-panel-slide-up;
    animation-direction: reverse;
    z-index: -1;
  }
  .di-card-text:not(:last-child) {
    margin-bottom: 1rem;
  }
}
@keyframes inspection-panel-slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
