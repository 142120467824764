@media screen {
  .shell-outer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
  }
}

@media print {
  .shell-outer {
    display: block;
  }
}

.shell-outer {
  width: 100vw;
}

.shell-outer-padded {
  padding-left: 8vw;
  padding-right: 8vw;
  flex-grow: 1;
}
.shell-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.simple-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  h1,
  h2 {
    color: $white;

    .large {
      margin-top: 0;
    }
  }
  .logo {
    height: 47;
    margin: 0;
  }
  .loader {
    position: relative;
    z-index: $z-index-l2;
    top: -47 / 2;
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    .toolbar-left {
      display: flex;
      flex-grow: 0;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
    }
    .toolbar-right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: row;
    }
  }
}

.loader {
  &-hidden {
    visibility: hidden;
  }
}
