.professional-overview {
  .report-section-row.current-roles .row .report-section-content-inner {
    padding: 16px 16px 16px 16px !important;
  }
  .report-section-row,
  .report-inner-section-header,
  .collapse {
    width: 100% !important;
  }
  .report-section-row {
    .report-inner-section-header {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &:not(:last-child):after {
      margin-bottom: 10px;
    }
    &.current-roles {
      .row {
        min-height: 35px;
      }
    }
    &.previous-roles {
      .report-inner-section-header {
        margin-bottom: 0;
        &---expanded {
          margin-bottom: 10px;
        }
      }
    }

    .report-section-content-inner {
      &.row {
        padding: 16px 16px 16px 16px !important;
      }
      &.company-status {
        padding: 16px 16px 16px 16px !important;
        flex-direction: row !important;
      }
    }
  }
  .ordered-by-info {
    color: $grey_hover;
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 16px;
  }
  .top-section {
    min-height: 60px;
  }
  .role-information-container {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid $gray_alpha;
    border-bottom: 1px solid $gray_alpha;
  }
  .image-container {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: initial;
    justify-content: initial;
    margin-top: 0;
    height: auto;

    .image {
      .sourcingImage {
        max-width: 40px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .row {
    margin-bottom: 1px;
  }
  .bottomSourceCard {
    margin-top: 10px;
    min-height: 30px;
  }
  .sourceCardTag {
    display: inline-flex;
    height: auto !important;
    width: auto !important;
    margin-right: 5px;
  }
  .report-link {
    text-align: right;
  }
  .company-status {
    .tags {
      max-height: 50px;

      .first-tag {
        display: flex;
      }

      &--expanded {
        max-height: none;
      }

      .full-text-container .full-text {
        right: 0;
      }
    }
  }
}
.report-section {
  .image-container {
    margin-top: 10px;
  }
  .tags {
    &--expanded {
      max-height: none;

      .more-items {
        height: 0 !important;
        display: block;
      }
    }
  }

  .professional-information {
    border-bottom: 1px solid white;
    margin: 0;
    .org-logo {
      min-height: 50px;
      min-width: 50px;
      max-width: 55px;
      max-height: 55px;
      margin: 0;
      padding: 0;
    }
  }
}

.confidence-scores {
  font-size: $font_size_sm;
}
