$stack_bar_color_0: #ee7796;
$stack_bar_color_1: #8e70b8;
$stack_bar_color_2: #33b8bf;
$stack_bar_color_3: #858996;
$stack_bar_color_4: #f39200;
$stack_bar_color_5: #61b232;
$stack_bar_color_6: #4674ba;
$stack_bar_color_7: #505f70;
$stack_bar_color_8: #d7487c;
$stack_bar_color_9: #007c89;
$stack_bar_color_10: #6a4394;
$stack_bar_color_11: #338c50;
$stack_bar_color_12: #9e5e22;
$stack_bar_color_13: #005e30;
$stack_bar_color_14: #9a2e41;
$stack_bar_color_15: #005572;

//Below are random colors not by UX
$stack_bar_color_16: #008941;
$stack_bar_color_17: #006fa6;
$stack_bar_color_18: #a30059;
$stack_bar_color_19: #ffdbe5;
$stack_bar_color_20: #7a4900;

.bar-wrapper {
  width: 100%;
  font-size: $font_size_sm;

  li:hover,
  .cursor:hover {
    cursor: default;
  }

  .bar--value {
    visibility: hidden;
  }

  .bar {
    &__color {
      &--0 {
        background: $stack_bar_color_0;
      }
      &--1 {
        background: $stack_bar_color_1;
      }

      &--2 {
        background: $stack_bar_color_2;
      }
      &--3 {
        background: $stack_bar_color_3;
      }
      &--4 {
        background: $stack_bar_color_4;
      }
      &--5 {
        background: $stack_bar_color_5;
      }
      &--6 {
        background: $stack_bar_color_6;
      }
      &--7 {
        background: $stack_bar_color_7;
      }
      &--8 {
        background: $stack_bar_color_8;
      }
      &--9 {
        background: $stack_bar_color_9;
      }
      &--10 {
        background: $stack_bar_color_10;
      }
      &--11 {
        background: $stack_bar_color_11;
      }
      &--11 {
        background: $stack_bar_color_11;
      }
      &--12 {
        background: $stack_bar_color_12;
      }
      &--13 {
        background: $stack_bar_color_13;
      }
      &--14 {
        background: $stack_bar_color_14;
      }
      &--15 {
        background: $stack_bar_color_15;
      }
      &--16 {
        background: $stack_bar_color_16;
      }
      &--17 {
        background: $stack_bar_color_17;
      }
      &--18 {
        background: $stack_bar_color_18;
      }
      &--19 {
        background: $stack_bar_color_19;
      }
      &--20 {
        background: $stack_bar_color_20;
      }
    }
  }

  .legend {
    &__label {
      &--0 {
        color: $stack_bar_color_0;
      }
      &--1 {
        color: $stack_bar_color_1;
      }
      &--2 {
        color: $stack_bar_color_2;
      }
      &--3 {
        color: $stack_bar_color_3;
      }
      &--4 {
        color: $stack_bar_color_4;
      }
      &--5 {
        color: $stack_bar_color_5;
      }
      &--6 {
        color: $stack_bar_color_6;
      }
      &--7 {
        color: $stack_bar_color_7;
      }
      &--8 {
        color: $stack_bar_color_8;
      }
      &--9 {
        color: $stack_bar_color_9;
      }
      &--10 {
        color: $stack_bar_color_10;
      }
      &--11 {
        color: $stack_bar_color_12;
      }
      &--12 {
        color: $stack_bar_color_12;
      }
      &--13 {
        color: $stack_bar_color_13;
      }
      &--14 {
        color: $stack_bar_color_14;
      }
      &--15 {
        color: $stack_bar_color_15;
      }
      &--16 {
        color: $stack_bar_color_16;
      }
      &--17 {
        color: $stack_bar_color_17;
      }
      &--18 {
        color: $stack_bar_color_18;
      }
      &--19 {
        color: $stack_bar_color_19;
      }
      &--20 {
        color: $stack_bar_color_20;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    column-count: 3;
    column-gap: 1px;
    li {
      -webkit-column-break-inside: avoid;
      word-break: break-word;

      .legend {
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 5px;
        margin-right: 10px;
      }
    }
  }
}
