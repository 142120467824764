div#modalContent {
  display: block;
  width: 50%;
  position: static;
}
.closeReadMe {
  color: $red_gray_mid;
  float: right;
  font-size: $font_size_lg;
  font-weight: bold;
  right: 27%;
  position: absolute;
  &:hover,
  &:focus {
    color: $black;
    text-decoration: none;
    cursor: pointer;
  }
}

.read-me__title {
  color: #232947;
}
