.MediaIcon_m18fmxjc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.MediaIcon_m18fmxjc > svg{width:20px;height:20px;border-radius:3px;}.MediaIcon_m18fmxjc > svg path{fill:#007AFF;}
.WebsiteIconContainer_wpgbojn.MediaIcon_m18fmxjc path{fill:#ffffff;}
.MediaItem_mv08iud{overflow:visible;white-space:nowrap;font-size:0.875rem;padding-bottom:var(--mv08iud-0);margin-right:var(--mv08iud-1);}.MediaItem_mv08iud:last-child{margin-right:0;padding-bottom:0;}.MediaItem_mv08iud .MediaIcon_m18fmxjc > svg{margin-right:var(--mv08iud-0);}
.MediaLink_mw3zw6r{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;max-width:var(--mw3zw6r-0);}.MediaLink_mw3zw6r:hover{color:#0095cc;}
.MediaItemContainer_m1k14avf{display:var(--m1k14avf-0);width:var(--m1k14avf-1);}
.FollowerCount_f1nrj110{color:#007AFF;font-size:0.875rem;min-width:30px;text-align:right;}
.Label_l193q8qu{margin-right:8px;}
.ProfileLabel_p1hbcvvr{padding-left:6px;}
.MediaLinkWithInspector_m11lq4be{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;max-width:var(--m11lq4be-0);}
.MediaItemWithInspector_m1hfh6dn.MediaItem_mv08iud{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:4px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
