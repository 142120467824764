.ButtonIcon_b10rz1u{background:var(--b10rz1u-0);color:var(--b10rz1u-1);border-radius:50%;min-width:18px;min-height:18px;max-width:18px;max-height:18px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:2px;}.ButtonIcon_b10rz1u svg{color:var(--b10rz1u-2);max-width:100%;max-height:100%;}
.ConfidenceType_csbmge9{color:#474952;text-transform:capitalize;}
.ConfidenceLabel_c1f4a9vo{text-transform:capitalize;color:#474952;cursor:pointer;}
.ConfidenceRadioInputContainer_crqaj10{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;font-size:0.875rem;color:#474952;cursor:pointer;}.ConfidenceRadioInputContainer_crqaj10 + .ConfidenceRadioInputContainer_crqaj10{margin-top:3px;}
.SelectionPrompt_s5iupb3{margin-bottom:14px;color:#858996;font-size:0.875rem;}
.DropdownToggle_d8p14iy{min-width:0 !important;min-height:0 !important;width:26px;height:26px;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border:none !important;background-color:var(--d8p14iy-0) !important;-webkit-transition:background-color 0.4s;transition:background-color 0.4s;box-shadow:none !important;}.DropdownToggle_d8p14iy:hover,.DropdownToggle_d8p14iy:focus{background-color:var(--d8p14iy-1) !important;}.DropdownToggle_d8p14iy:hover > svg > circle,.DropdownToggle_d8p14iy:focus > svg > circle{fill:var(--d8p14iy-2);}
.TooltipContent_t14bkqrd{padding:14px;max-width:410px;font-size:0.875rem;}
.FeatureInfo_fk31i37{padding:10px;font-size:0.875rem;}
.SupportLink_s8pfcdq{color:#007AFF !important;-webkit-text-decoration:underline !important;text-decoration:underline !important;}
.PromptText_pnkdt14{font-size:0.875rem;color:#474952;}.PromptText_pnkdt14 span{color:#7439B3;}
.PromptSubtext_poybu9g{font-size:0.875rem;color:#474952;margin-top:5px;}
.UserSetInfoText_u1fielki{text-transform:none;}
.MenuButtonIcon_m1qef68n{height:18px;}.MenuButtonIcon_m1qef68n > circle{fill:var(--m1qef68n-0);}
.TooltipExplainerText_twm98kf{color:#858996;font-size:0.875rem;}
.DisabledConfirmingModalContainer_dce0jc5{cursor:auto;background:#ffffff;padding:2rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.PrimaryActionString_pgh9spx:first-letter{text-transform:capitalize;}
