.descriptor {
  font-size: $font_size_sm;
  color: $grey_dark;
  display: inline;
  font-family: $Inter_Light;
}

.descriptor-count {
  color: $grey_mid;
  font-family: $Inter_Light;
  font-size: $font_size_sm;
}
