.report-section-row .report-section-content-outer {
  .report-table-row {
    display: flex;
    flex-direction: center;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 26px;
      height: 26px;
      margin-right: 2px;
    }
  }
  .report-table-header {
    display: inline-block;
    color: $grey_mid;
    padding-right: 0.5em;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  .report-table-row {
    &:not(:first-child) {
      .report-table-header {
        margin-top: 12px;
      }
    }
  }
}
