.SectionHeader_syekuln{border-bottom:2px solid #ffffff;padding:0 16px 16px 16px;margin:0 -17px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.FilterHeader_fbl527j{color:#858996;font-size:0.875rem;padding-right:15px;}
.ResultsSection_rsbi9jv{max-height:var(--rsbi9jv-0);overflow-y:auto;background-color:#ffffff;}.ResultsSection_rsbi9jv .professional-information{border-bottom:1px solid #d2d3d8;margin-right:16px;}.ResultsSection_rsbi9jv .professional-information:last-child{border-bottom:1px solid transparent;}
.ListTitle_lxkg4x1{font-size:0.875rem;color:#858996;}
.SubSectionHeader_s1xcb73d{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:10px;}
.SubSectionTitle_s678khb{font-size:0.875rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.FilterBarsHeader_fzrmvzf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:5px;padding-top:8px;}.FilterBarsHeader_fzrmvzf span{font-size:0.875rem;}
.MetaInformation_m1unmxia{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:0.875rem;color:#858996;}
.AverageTenureLabel_a15s8azz{color:#858996;font-size:0.75rem;padding-left:5px;}
.ActiveFiltersContainer_ariy8rn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ActiveFilterPill_a10ou6j1{font-size:0.875rem;background-color:var(--a10ou6j1-0);opacity:0.6;border-radius:17px;color:#ffffff;padding:1px 7px 1px 11px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-left:10px;}
.ActiveFilterPillLabel_aei47jh{padding-right:5px;font-size:0.875rem;}
.ActiveFiltersLabel_a18vt5ey{color:#858996;font-size:0.875rem;}
.CrossIcon_cp724ie{height:20px;width:20px;}.CrossIcon_cp724ie circle{stroke:transparent;}.CrossIcon_cp724ie:hover{cursor:pointer;}
.CurrentRolesBarContainer_cbxgj2k{margin-bottom:20px;}
.EmploymentDetailsContainer_e10r5k9t{margin-bottom:var(--e10r5k9t-0);}
.CustomStickyExpandButton_cuywxt5{background-color:#ffffff;}
.Chevron_c1g2jdp1{width:6px;-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);}.Chevron_c1g2jdp1 path{fill:#474952;}
.ConfidencebucketCount_c17qz2de{font-size:0.875rem;color:#474952 !important;padding-right:6px;}
.OptionsDropdownMenuItem_o780iwf{padding:8px;color:#474952;font-size:0.875rem !important;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;-webkit-transition:background-color 0.2s;transition:background-color 0.2s;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.OptionsDropdownMenuItem_o780iwf:hover,.OptionsDropdownMenuItem_o780iwf:focus{background-color:var(--o780iwf-0);}.OptionsDropdownMenuItem_o780iwf:disabled{opacity:0.3;color:#ffffff;}.OptionsDropdownMenuItem_o780iwf:focus{outline:none;}.OptionsDropdownMenuItem_o780iwf:active{color:#474952;}
.DisabledOptionsDropdownMenuItem_d1d7znjn.OptionsDropdownMenuItem_o780iwf{opacity:0.3;}
.NoneIdentifiedText_na7mjgt{font-size:0.875rem;color:#b3b5bd;font-style:italic;}
.TooltipContent_t2n2tg6{text-align:left;padding:2px;width:var(--t2n2tg6-0);max-width:275px;max-height:325px;overflow-y:auto;}
