.sort-filter-pill-container {
  margin: 8px 2px;
}
.sort-filter-dropdown-menu-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: $Inter_Regular;
}
.sort-filter-pill {
  border-radius: 100px; /* larger than the height of the pill, will do semi-circular ends of the pill */
  border-color: $grey_dark;
  color: $grey_dark;
  padding: 3px 10px;
  background-color: $white;
  outline: none;

  &:hover:not(:disabled) {
    background-color: $white;
    color: $blue_icon;
    border-color: $blue_icon;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:focus {
    background-color: $white;
    color: $blue_icon;
    border-color: $blue_icon;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &-open {
    border-color: $blue_icon;
    color: $blue_icon;
    &:hover {
      background-color: $white;
      color: $blue_icon;
      border-color: $blue_icon;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    :focus {
      background-color: $white;
      color: $blue_icon;
      border-color: $blue_icon;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  &-filled {
    border-radius: 100px;
    border-color: $blue_icon;
    color: $white;
    background-color: $blue_icon;
    &:hover,
    &:focus {
      border-color: $blue_icon;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  &-inner {
    border-radius: 5px;
    border-color: $gray_default_dark;
    color: $gray_default_dark;
    padding: 2px 5px;
    background-color: $white;

    &:hover {
      background-color: $white;
      color: $blue_icon;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &:focus {
      background-color: $white;
      color: $blue_icon;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
.sort-filter-dropdown-menu-bottom-button-container {
  display: flex;
  width: 100%;
  width: -webkit-fill-available;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 7px;

  button {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    border: none;
    width: min-content;
    line-height: 16px; /* means the space at the bottom of the dropdown looks right, because this moves the text down a bit*/

    &:focus {
      outline: none;
    }
  }
}
.sort-filter-label-overflow {
  max-width: 235px;
}

.sort-filter-pill__ellipsis-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
