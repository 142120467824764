.StatusHeading_suu3sa2{color:#ffffff;padding-left:0.5rem;}
.Building_b205tmc{-webkit-transform:scale(0.875);-ms-transform:scale(0.875);transform:scale(0.875);}
.LoadingBar_lvqsgq7{position:absolute;top:-1px;left:-1px;width:104%;height:104%;border-radius:50%;border:4px solid #0E1A29;border-left-color:#28e4fa;-webkit-animation:rotate-LoadingBar_lvqsgq7 1.5s linear infinite;animation:rotate-LoadingBar_lvqsgq7 1.5s linear infinite;}@-webkit-keyframes rotate-LoadingBar_lvqsgq7{0%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}100%{-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg);}}@keyframes rotate-LoadingBar_lvqsgq7{0%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}100%{-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg);}}
.AvatarIconContainer_a81c9tk{position:relative;border-radius:50%;}
.FailedReportTooltip_f1pppboa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:1.25rem;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;gap:1.25rem;font-size:0.875rem;}
.FailedReportTooltipBody_fcq68pm{margin:0;}
.ContactSupportLink_c194q7m4{color:#007AFF !important;cursor:pointer;-webkit-text-decoration:none !important;text-decoration:none !important;}.ContactSupportLink_c194q7m4:hover{-webkit-text-decoration:underline !important;text-decoration:underline !important;}
.card_c1b2e25y{cursor:pointer;}.card_c1b2e25y:hover{outline:2px solid #28effa;}
.cardUpdating_cy1mtmw{cursor:pointer;}.cardUpdating_cy1mtmw:hover{outline:2px solid rgba(40,228,250,0.4);}
.SolidZap_s13e545{fill:#474952;height:16px;width:16px;margin-left:4px;}
.GreyedOutZap_gw36pxo{color:#b3b5bd;height:16px;width:16px;margin-left:4px;}
