.di-card-text {
  margin: 0.2rem;
  overflow-wrap: break-word;
  overflow: hidden;
}
.di-card-text-label {
  font-size: $font_size_sm;
  color: $grey_mid;
}
.small-text,
.smaller-text {
  color: $grey_hover;
}
.small-text {
  font-size: $font_size_sm;
}
.smaller-text {
  font-size: $font_size_sm;
}
