.regenerate-report-bar {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  color: $white;
  border-radius: 4px;
  padding: 16px;
  font-size: $font_size_sm;
  font-family: $Inter_Light;
  flex-grow: 1;
  position: fixed;
  bottom: 0;
  background-color: $gray_mid_dark;
  max-width: 1020px;
  align-self: center;
  width: 100%;
  height: 60px;
  -webkit-box-shadow: 7px 8px 12px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 8px 12px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 8px 12px -10px rgba(0, 0, 0, 0.75);
  z-index: 8;

  .regenerate-report-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    flex-grow: 1;
    padding-left: 4px;
    > span:not(:last-child) {
      padding-right: 0.25rem;
    }
  }
}
