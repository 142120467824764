.source-stack-expanded-contents {
  grid-column: 1/-1;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 2px solid $grey_panel;
  border-bottom: 2px solid $grey_panel;
}

.media-results-expanded {
  grid-template-columns: repeat(auto-fill, 480px);
  border-top: 2px solid $white;
  border-bottom: 2px solid $white;
}
