.paginated-list {
  &__container {
    position: relative;
    height: auto !important;
    line-height: 1;
    margin: 0;
    border-top: 1px solid $grey_light;
    border-bottom: 1px solid $grey_light;
    padding: 4px 0;

    .close {
      font-size: $font_size_md;
    }
    dl {
      margin-bottom: 0;
      padding-bottom: 0;

      dt {
        height: 22px;
      }

      dd {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;

        li:last-child {
          margin-bottom: 0;
        }
      }
    }
    .paginated-list__content {
      li {
        margin-bottom: 4px;
      }
    }
    .link {
      &__show-all-container {
        display: flex;
        justify-content: space-between;
      }

      &__show-all {
        font-size: $font_size_sm;
      }
    }

    &--active {
      z-index: $z-index-l2;
      background-color: $grey_panel;
      position: relative;
      left: 0;
      top: 0;

      .paginated-list__content {
        margin-top: 14px;
        height: 78%;
        position: relative;
        width: 100%;

        dt {
          line-height: 1;
          margin-bottom: 0;
        }
      }

      .link {
        &__show-all-container {
          width: 100%;
          height: 23px;
          height: 20px;
        }
        &__show-all {
          right: 16px !important;
          top: 16px !important;
        }
      }
    }

    ul {
      li {
        .list-bullet {
          height: 6px;
          width: 6px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 10px;
          background: $grey_dark;
        }

        > * {
          flex-grow: 0;
        }
      }
    }
    .link__show-all {
      cursor: pointer;
      align-self: flex-end;
      right: 0;
      top: 0;
      color: $grey_mid;

      &:hover {
        color: $black;
      }

      .close {
        line-height: 0.8;
      }
    }

    .toggle-display {
      &.hide {
        display: none;
      }
    }
    .pagination {
      width: 100%;
      bottom: 0;
      display: flex;
      align-self: baseline;
      position: relative;
      margin-top: 8px;
      height: 16px;

      &__next,
      &__prev {
        display: block;
        width: auto;
        cursor: pointer;
        color: $grey_mid;
        position: absolute;
        top: 0;

        &:hover {
          color: $black;
        }
      }
      &__next {
        right: 0;
      }
      &__prev {
        left: 0;
      }
    }
  }
}
