.experimental-section {
  .two-columns {
    background-color: $white;
    column-count: 2;
    column-gap: 0px;
    width: 100%;
  }

  .nlp {
    margin-right: 8px;
    height: 16px;
    width: auto;
  }

  .reference {
    color: $grey_mid;
    font-family: $Inter_Light;

    &:after {
      content: "\00a0";
    }
  }

  .list {
    border: 1px solid $grey_panel;
    border-left: 0;
    border-top: 0;
    padding: 16px;
    background-color: $white;
    page-break-inside: avoid;

    .descriptor {
      font-size: $font_size_sm;
      color: $grey_dark;
      display: inline;
      font-family: $Inter_Light;
    }

    .heading {
      margin-bottom: 10px;
      font-size: $font_size_lg;
      color: $grey_dark;
      font-family: $InterDisplay;
    }
    ul {
      list-style-type: none;
      margin: 0 0 0 10px;
      padding-left: 0;
    }
    li {
      justify-content: flex-start;
      -webkit-column-break-inside: avoid;
      font-size: $font_size_sm;
      color: $grey_dark;
      font-family: $Inter_Light;
      .subtext {
        display: inline;
        font-family: $Inter_Light;
      }
      .list-bullet {
        height: 4px;
        width: 4px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 8px;
        background: $grey_mid;
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }
    li > * {
      vertical-align: text-top;
      -webkit-column-break-inside: avoid;
    }
    .list-group-contents {
      margin-bottom: 12px;
      padding-left: 12px;
    }
  }

  .toggle-icon {
    &__extra-information {
      width: 10px;
      height: 10px;
      margin-left: -2px;
      margin-right: 3px;

      &--expanded {
        transform: rotate(90deg);
      }
    }
  }
}

.experimental-section__list-footer {
  font-size: $font_size_sm;
  padding-top: 10px;
  color: $grey_mid;
}

.experimental-section__secondary-info {
  display: inline;
  color: $grey_dark_mid;
  font-size: $font_size_sm;
}

.experimental-section__group-label {
  color: $grey_mid;
  font-size: $font_size_sm;
}

.experimental-section__relation-type {
  font-family: $Inter_Light;
}

.org-relations__list {
  padding: 0 0 10px 0 !important;
  border: none !important;
}

.org-relations__title {
  font-size: $font_size_md !important;
  margin-bottom: 0 !important;
  padding-bottom: 5px;
}
