.RiskPill_rs30nsp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:var(--rs30nsp-0);padding-left:8px !important;padding-right:8px !important;position:relative;border:1px solid var(--rs30nsp-1) !important;border-radius:16px;gap:2px;min-height:22px !important;min-width:0 !important;height:22px;-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;font-size:0.875rem;text-transform:capitalize;background-color:var(--rs30nsp-2) !important;color:var(--rs30nsp-3) !important;}.RiskPill_rs30nsp:hover,.RiskPill_rs30nsp:focus{box-shadow:0 0 0 4px rgba( var(--rs30nsp-4), 0.25 ) !important;cursor:var(--rs30nsp-5);}
.IconContainer_i1nn6tq7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-left:6px;}.IconContainer_i1nn6tq7 > svg{width:16px;height:16px;}.IconContainer_i1nn6tq7 > svg path{fill:var(--i1nn6tq7-0);}
.KillButton_k6mqkni{border:none;background:none;height:26px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-right:0;padding-inline:6px;cursor:pointer;}.KillButton_k6mqkni:focus{outline:none;}.KillButton_k6mqkni svg{height:16px;width:16px;}.KillButton_k6mqkni path{-webkit-transition:fill 0.2s;transition:fill 0.2s;fill:var(--k6mqkni-0);opacity:0.6;}.KillButton_k6mqkni circle{-webkit-transition:fill 0.2s;transition:fill 0.2s;stroke:transparent;}.KillButton_k6mqkni:hover:not(:disabled) path{opacity:1;fill:var(--k6mqkni-1);}.KillButton_k6mqkni:hover:not(:disabled) circle{fill:var(--k6mqkni-2);}
.KillButtonDisabled_k1v1mq81.KillButton_k6mqkni{cursor:not-allowed;opacity:0.5;}
.VerticalSeparator_v99uf7n{padding-bottom:2px;}
.PillLabel_p1rg784q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.RightPart_rwohj5h{position:relative;height:22px;min-width:var(--rwohj5h-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-transform:none;}
.CountDiv_c10gp8zy{position:relative;}.CountDiv_c10gp8zy span{opacity:var(--c10gp8zy-0);-webkit-transition:opacity 0.2s;transition:opacity 0.2s;}
.KillDiv_kbvgs2j{position:absolute;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;top:-3px;opacity:var(--kbvgs2j-0);-webkit-transition:opacity 0.2s;transition:opacity 0.2s;width:100%;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.RemovalDisclaimer_r1o2exw2{padding-top:6px;}
.Tooltip_t4yv7fr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
