.TopRow_t1tdzsew{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.CustomFlag_cbcbdyx{width:30px;}.CustomFlag_cbcbdyx img{width:24px !important;height:18px !important;margin:0;margin-top:1px !important;margin-right:8px !important;border-radius:2px !important;}
.CountryField_ces7kh1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-top:2px;}
.CountryName_c151ps0a{font-size:0.875rem;color:#474952;}
.SubTitle_s13ryjml{font-size:0.875rem;color:#858996;}
.SubText_se90530{font-size:0.875rem;color:#474952;}
.NameField_n185daeq{font-size:1rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;line-height:22px;margin-top:-4px;}
.FlagPillsContainer_fejc0p{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:5px;padding-top:11px;}
.AkasAndAgeField_a1ya57pm{font-size:0.875rem;color:#474952;padding-top:7px;}
.Date_d3ksyn5{color:#858996;font-size:0.875rem;text-transform:capitalize;}
.BirthDateField_b1exoghs{color:#858996;}
.Akas_anv0owj{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;}
.Age_anb27is{color:#474952;}
.RelationshipType_r1uftrhh{color:#474952;font-size:1rem;line-height:20px;padding-top:5px;}
.Rule_r77hjcq{width:100px;height:1px;background-color:#d2d3d8;margin-top:4px;margin-bottom:6px;}
.SubsidiaryField_s1xod5am{padding-bottom:4px;}
.DeceasedLabel_dezyspn{padding-top:7px;}
.AdditionalCount_aha68b8{color:#858996;}
.DividingLine_duuyygz{height:1px;margin:10px 0;border-top:1px solid #d2d3d8;}
.AssociateRiskCounts_a1ihrbnj{margin-top:8px;}
.AssociatesWithRiskLabel_azw5ydu{color:#ef7273;font-size:0.875rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin-bottom:0;}
