.DescriptionText_d1b007i7{color:#474952;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:24px;font-size:1rem;margin-bottom:32px;}
.CtaText_c17npy49{color:#474952;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:24px;font-size:1rem;margin-bottom:12px;}
.Option_o1wcit1j{margin-bottom:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;}
.OptionTitle_o132xmo0{color:#474952;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:24px;font-size:1rem;-webkit-letter-spacing:-0.49px;-moz-letter-spacing:-0.49px;-ms-letter-spacing:-0.49px;letter-spacing:-0.49px;margin-bottom:0;-webkit-flex:1;-ms-flex:1;flex:1;}
.OptionsContainer_o4o3k3z{background-color:#fbfbfb;padding:16px;box-shadow:0px 0px 1px 0px rgba(0,0,0,0.25) inset;border-radius:10px;margin-bottom:32px;}.OptionsContainer_o4o3k3z .Option_o1wcit1j:last-of-type{margin-bottom:0;}
.Actions_ati6qaq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.CancelButton_coxkki1{background-color:#EDEDED;padding:12px 16px;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.ExportButton_e15wpx0s{background-color:#1F7DD9;color:#ffffff;padding:12px 24px;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.ToggleContainer_t189o48p{display:block;width:40px;height:20px;padding:2px;border-radius:20px;background-color:var(--t189o48p-0);}
.Toggle_t1gdt7uj{width:16px;height:16px;border-radius:20px;background-color:#ffffff;-webkit-transform:var(--t1gdt7uj-0);-ms-transform:var(--t1gdt7uj-0);transform:var(--t1gdt7uj-0);-webkit-transition:-webkit-transform ease 0.3s;-webkit-transition:transform ease 0.3s;transition:transform ease 0.3s;}
