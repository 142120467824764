.map-transparent-btn {
  color: $grey_mid;

  &:hover,
  &.hover {
    color: $button_transparent_hover;
  }
  &.active {
    color: $grey_mid !important;
  }

  img {
    margin-right: 8px;
  }
}
