/******************************************

TODO

- combine the  grays into a smaller subset, there are currently 29 shades of gray
- remove/replace any instances of "used once only" where approriate

******************************************/

$white: white;

$black: black;
$black_shadow: rgba(0, 0, 0, 0.05);
$black_shadow_dark: rgba(0, 0, 0, 0.1);

$grey_lightest: #fafafa;
$gray_lighter: #fbfbfb;
$grey_panel: #ededed;
$grey_panel_light: #f2f2f2;
$grey_panel_border: #e9eaec; //rename
$grey_card_hover: #dedfe3; //rename
$grey_light: #dfe1e3;
$grey_rule: #d2d3d8;
$grey_light-mid: #ccc;
$grey_ghost: #b3b5bd;
$grey_mid: #858996;
$gray_mid_dark: #717684; //used once only
$gray_mid_dark_alpha_06: rgba(113, 118, 132, 0.6);
$grey_dark: #474952;
$grey_default: gray; //rename me
$gray_default_dark: dimgray;
$grey_dark_mid: #4d4d4d;
$grey_darkest: hsla(0, 0%, 20%, 0.9);
$grey_hover: #848895; //rename me
$gray_alpha: rgba(0, 0, 0, 0.1);
$gray_mid_42: #6b6b6b;
$darkgrey_companyStatusGeneral: darkgrey;

$blue_dark: #2f5597;
$blue_dark_alpha_25: rgba(0, 0, 60, 0.25);
$blue_dark_alpha_60: rgba(0, 0, 60, 0.6); //used only once
$blue_icon: #007aff;
$blue_hover: #0095cc;
$blue_mid_darkest: #4674ba;
$blue_mid_dark: #009fcc; //used once only
$blue_mid_darker: rgb(31, 119, 180); //used once only
$blue_mid: #33b8bf;
$blue_highlight_hover: royalblue;
$blue_paywall: #22c3bb;
$blue_mid_bg: #647faa;
$blue_lighter: #a8d2d8;
$blue_light: #8cabd1;
$blue_lightest: #cacece;

$blue_grey: #8796ae;
$blue_grey_mid: #99a1a7;
$blue_grey_light: #adb8c0;
$grey_lighter: #acb2c6;
$grey_grey_lightest: #e9ecee;
$blue_grey_super_light: #f2f9fc;
$blue_tag_bg: #57abcc;

$purple_darkest: #670c70;
$purple_dark: rebeccapurple;
$purple_light: rgb(154, 152, 158); //used only once

$red: red;
$red_dark: #df0000;
$red_darkish: #d90200;
$red_darkish_alpha_06: rgba(217, 2, 0, 0.6);
$red_dark_alpha_06: rgba(0, 0, 0, 0.06);
$red_mid: indianred;
$red_black: rgba(51, 51, 51, 1); //used only once
$red_black_alpha_80: rgba(51, 51, 51, 0.8); //used only once
$red_shadow: rgba(255, 255, 255, 0.1);
$red_grey_light: #e4e4e4; //used only once
$red_gray_light_mid: rgb(241, 241, 241); //used only once
$red_gray_mid: #aaa; //used only once
$red_gray_dark: dimgrey;
$red_gray_darker: #999;
$red_gray_darkest: #333;
$red_regular: #f01910;

$amber_regular: #f39200;
$orange_dark: #eb3c48;
$orange_dark_mid: #ff5400;
$orange_di: #ff5400; /* don't use for new stuff - use linaria style */
$green_xapien: #19a49b; /* don't use for new stuff - use linaria style */
$orange_mid: #f27121;
$orange_hover: #e64d00;
$orange_light: #f58f97;
$orange_rebroker: #cd7e80;
$orange_light_tag_bg: rosybrown;
$orange_amber: #ff9d00;
$orange_amber_light: #c9921d;
$orange_darker: #d25400;

$yellow_mid: #d2d60c;

$green_dark: #267100;
$green_mid_darker: #204f4f;
$green_mid_dark: #4e7276;
$green: #338c50;
$green_alpha_06: rgba(51, 140, 80, 0.6);
$green_mid: #318b50;
$green_light: #75cf64;
$green_light_tag_bg: #00cca7;
$green_lightest: #83af79;

$brown: saddlebrown;
$brown_mid: brown;
$brown_mid_alpha_06: rgba(165, 42, 42, 0.6);
$brown_light: rosybrown;
$brown_dark: #9e5e22;
$brown_dark_alpha_06: rgba(158, 94, 34, 0.6);

$purple_mid: #8e70b8;

$country_risk_warning: #ef7273;

$link_blue: $blue_dark;

$risk: $orange_dark;
$risk_opacity: $orange_light;

$source: $grey_light-mid;
$source_opacity: $blue_light;

$subject: $blue_mid;
$subject_opacity: $blue_lighter;

$active: $green;
$active_opacity: $green_mid;

$risk_red: $red_dark;
$risk_amber: $orange_amber;
$risk_green: $green_light;

$report_header_background: $orange_mid;

$tag_container_background: $blue_mid_dark; //used once only
$sourceCardList: $grey_mid;
$sourceCardTagBg_Paywall: $blue_paywall;
$sourceCardTagBg_Mentioning: $green_lightest;
$sourceCardTagBg_Featuring: $blue_mid_bg;
$sourceCardTagBg_Montage: $orange_light_tag_bg;
$sourceCardTagBg_Profile: $green_light_tag_bg;
$sourceCardTagBg_Authored: $yellow_mid;
$sourceCardTagBg_SocialProfile: $green_mid_dark;
$sourceCardTagBg_NotRelevant: $red_gray_dark;
$sourceCardTagBg_Rebrokered: $grey_mid;
$sourceCardTagBg_NotProcessed: $blue_tag_bg;
$sourceCardTagBg_CouldNotIdentifySubject: $purple_mid;
$sourceCardTagBg_TagGeneral: $green_mid_darker;
$sourceCardTagBg_SidebarContent: $orange_amber_light;
$sidebarContent_underline: rgba($orange_amber_light, 0.6);
$companyStatus_General: $darkgrey_companyStatusGeneral;
$companyStatus_Liquidation: $red_mid;
$companyStatus_Insolvency: $brown_light;
$companyStatus_Administration: $brown_mid_alpha_06;
$companyStatus_Dissolved: $brown_dark_alpha_06;
$companyStatus_Problem_Generic: $red_darkish_alpha_06;

$companyStatus_Active: $green_alpha_06;
$companyStatus_Inactive: $brown_dark_alpha_06;
$companyStatus_Warning: $red_darkish_alpha_06;
$companyStatus_Unknown: $gray_mid_dark_alpha_06;

$button_transparent_hover: $gray_mid_dark; //used once only

$tooltip_border: $grey_darkest;

$checkbox_shadow: $black_shadow;
$checkbox_shadow_dark: $black_shadow_dark;
$checkbox_shadow_checked: $red_shadow;
