.ItemLink_i8kog5o{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.ItemLink_i8kog5o:hover{color:#0095cc;}
.BulletContainer_bw2fj8t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.Bullet_b8itbni{height:6px;width:6px;display:inline-block;border-radius:50%;margin-right:10px;background:#474952;}
.ListItem_lnldnz0{font-size:0.875rem;margin-bottom:4px;padding:0;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}.ListItem_lnldnz0:last-child{margin-bottom:0;}
.ListContainer_l1k5cmz1{position:relative;height:auto !important;line-height:1;margin:0;padding:4px 0;}
.ListHeading_l1g0x4q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;height:var(--l1g0x4q-0);}
.ListTitle_l1xlu4zr{margin-right:4px;color:#6b6b6b;font-size:1.5rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.ListSubTitle_ld41sbm{color:#858996;}
.ShowAllToggle_s1741jnw{cursor:pointer;-webkit-align-self:flex-end;-ms-flex-item-align:end;align-self:flex-end;right:0;top:0;color:#858996;font-size:0.875rem;}.ShowAllToggle_s1741jnw > button{font-size:1rem;line-height:0.8;}.ShowAllToggle_s1741jnw:hover{color:#000000;}
.ContentContainer_c1s0z02w{height:var(--c1s0z02w-0);position:relative;width:100%;}
.DescriptionList_diloqnw{margin-bottom:0;}
.DescriptionDetails_d14pqtzf{margin-bottom:0;}
.List_l4e8m0l{list-style-type:none;margin:0;margin-left:16px;padding-left:0;}
.Pagination_p19c2v3d{width:100%;bottom:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-self:baseline;-ms-flex-item-align:baseline;align-self:baseline;position:relative;margin-top:8px;height:16px;}
.PaginationControl_p486kz7{display:block;width:auto;cursor:pointer;color:#858996;position:absolute;top:0;}.PaginationControl_p486kz7:hover{color:#000000;}
.PaginationPrevControl_p1s8z5la.PaginationControl_p486kz7{left:0;}
.PaginationNextControl_plagr7x.PaginationControl_p486kz7{right:0;}
.Accordion_a14pene0{margin-top:10px;border-top:1px solid #d2d3d8;padding:3px 0;}
.OverlapTitle_ou73tkw{font-size:1.5rem;}
