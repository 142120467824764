.di-tag {
  color: $white;
  opacity: 1;
  font-size: $font_size_sm;
  white-space: nowrap;
  padding: 5px 6px;
  border-radius: 4px;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &.di-tag-selectable {
    opacity: 0.3;
    &:hover {
      cursor: pointer;
    }
  }
  &.di-tag-selectable:hover,
  &.di-tag-selected {
    opacity: 1;
  }
  &.expand-collapse-tags.di-tag-selectable {
    color: $link_blue;
  }
}
.sourceCardTag {
  color: $white;
  opacity: 1;
  font-size: $font_size_xs;
  white-space: nowrap;
  padding: 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;

  &.company-status {
    border-radius: 11px !important;
    padding: 2px 10px;
  }
}

a.companyStatusWarning {
  background-color: $companyStatus_Warning;
  color: $white;
  margin-bottom: 7px;
}

.sourceCardTagPaywall {
  background-color: $sourceCardTagBg_Paywall;
  color: $white !important;
}
.sourceCardTagMentioning {
  background-color: $sourceCardTagBg_Mentioning;
}
.sourceCardTagFeaturing {
  background-color: $sourceCardTagBg_Featuring;
}
.sourceCardTagMontage {
  background-color: $sourceCardTagBg_Montage;
}
.sourceCardTagProfile {
  background-color: $sourceCardTagBg_Profile;
}
.sourceCardTagAuthoredBy {
  background-color: $sourceCardTagBg_Authored;
}
.sourceCardTagSocialProfile {
  background-color: $sourceCardTagBg_SocialProfile;
}
.sourceCardList {
  background-color: $sourceCardList;
}
.sourceCardTagNotRelevant {
  background-color: $sourceCardTagBg_NotRelevant;
}
.sourceCardTagRebrokered {
  background-color: $sourceCardTagBg_Rebrokered;
}
.sourceCardTagNotProcessed {
  background-color: $sourceCardTagBg_NotProcessed;
}
.sourceCardTagCouldNotIdentifySubject {
  background-color: $sourceCardTagBg_CouldNotIdentifySubject;
}
.sourceCardTagGeneral {
  background-color: $sourceCardTagBg_TagGeneral;
}
.sourceCardTagSidebarContent {
  background-color: $sourceCardTagBg_SidebarContent;
}

.companyStatusDissolved {
  background-color: $companyStatus_Dissolved;
}
.companyStatusGeneral {
  background-color: $companyStatus_General;
}
.companyStatusLiquidation {
  background-color: $companyStatus_Liquidation;
}
.companyStatusInsolvency {
  background-color: $companyStatus_Insolvency;
}
.companyStatusAdministration {
  background-color: $companyStatus_Administration;
}

.companyStatusProblemGeneric {
  background-color: $companyStatus_Problem_Generic;
}

.companyStatusActive {
  background-color: $companyStatus_Active;
}
.companyStatusInactive {
  background-color: $companyStatus_Inactive;
}
.companyStatusWarning {
  background-color: $companyStatus_Warning;
}
.companyStatusUnknown {
  background-color: $companyStatus_Unknown;
}
