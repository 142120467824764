.DisclaimerContainer_d1swaefy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;gap:8px;}
.DisclaimerStars_dzsgjj9{width:9px;fill:#FFD268;}
.DisclaimerText_d1upbnwp{margin-bottom:0;text-align:center;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.625rem;font-weight:600;line-height:1.33333333;color:#474952;}
.TooltipContainer_taz1hsk{background-color:#fafafa;border-radius:20px;color:#474952;width:490px;}
.TooltipBody_t1iyin9t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:24px 24px 12px;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;gap:16px;-webkit-align-self:stretch;-ms-flex-item-align:stretch;align-self:stretch;}
.TooltipFooter_tfay770{position:relative;z-index:7;padding:12px 24px;background:#EDEDED;border-radius:0px 0px 12px 12px;margin-bottom:0;color:#0E1A29;width:100%;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;font-weight:500;line-height:1.44444444;}.TooltipFooter_tfay770 > a{color:#1F7DD9;-webkit-text-decoration:underline;text-decoration:underline;}
.TooltipIcon_t5xjx46{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:48px;height:48px;padding:12px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:12px;background:var(--t5xjx46-0);box-shadow:var(--t5xjx46-1);border-radius:var(--t5xjx46-2);}.TooltipIcon_t5xjx46 svg{width:24px;height:24px;}
.TooltipContent_td2c4x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:8px;}
.TooltipTitle_tw7a39n{color:var(--tw7a39n-0);font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1.125rem;font-weight:700;line-height:1.44444444;margin-bottom:0;}
.TooltipText_tihyydh{font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;line-height:1.5;margin-bottom:0;}.TooltipText_tihyydh > span{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:700;}.TooltipText_tihyydh a{-webkit-text-decoration:none;text-decoration:none;color:#1F7DD9;}
.ActionButton_a1ws9wln{background:#EBE5F2;color:#474952;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:100px;}.ActionButton_a1ws9wln:focus{outline:none;}.ActionButton_a1ws9wln:hover{background:#DBCFE8;color:#474952;}.ActionButton_a1ws9wln:disabled{background-color:#dfe1e3;}
.IconWrapper_i1glrmx7{background:var(--i1glrmx7-0);color:#ffffff;border-radius:50%;width:var(--i1glrmx7-1);height:var(--i1glrmx7-1);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.IconWrapper_i1glrmx7 svg{max-width:100%;max-height:100%;}
.DiscardedCover_d1nr78nj{z-index:7;position:absolute;border-radius:20px;top:0;left:0;width:100%;height:95%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:rgba(255,255,255,0.85);}
.DiscardedCoverHeading_d2ebge0{color:#7439B3;font-weight:500;}
.Spinner_sjfbyv0{width:21px;height:21px;border-width:2px;}
