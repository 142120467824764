.report-flow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /*flex-start;*/
  flex-wrap: wrap;

  .flow-container {
    cursor: default;
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0.3rem;
  }
  .flow {
    background-color: $grey_light;
    border-radius: 4px;
    padding: 0.3rem;
    display: flex;
    align-items: flex-start;
    height: 100%;
    width: 17.5vw;
    max-width: 14.3rem;
    min-width: 11.2rem;
  }
  .report-flow-item {
    height: 100%;
  }
}
