.InspectorContainer_i1r3x4jk{display:var(--i1r3x4jk-0);}
.InspectorInnerContainer_i13b6beu{color:#474952;width:100%;display:var(--i13b6beu-0);-webkit-transition:background-color 0.2s,box-shadow 0.2s;transition:background-color 0.2s,box-shadow 0.2s;border-radius:var(--i13b6beu-1);line-height:20px;}.InspectorInnerContainer_i13b6beu:hover,.InspectorInnerContainer_i13b6beu:focus{outline:none;background-color:var(--i13b6beu-2);box-shadow:var(--i13b6beu-3);cursor:pointer;}.InspectorInnerContainer_i13b6beu:hover:has(> [class*="SourcingPrompt"]) > [class*="SourcingPrompt"]{opacity:1;}
.Popover_phw4q9b{display:var(--phw4q9b-0) !important;}.Popover_phw4q9b + div{display:inline;}
.TooltipBody_t1jllphk{min-height:100px;width:460px;background-color:#EDEDED;border-bottom-left-radius:10px;border-bottom-right-radius:10px;border-top-left-radius:var(--t1jllphk-0);border-top-right-radius:var(--t1jllphk-0);}
.TooltipHeader_t1gs6wtv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-start-start-radius:10px;border-start-end-radius:10px;width:460px;background:#dfe1e3;}
.TooltipHeaderTitle_tyiapaa{padding-left:12px;padding-top:8px;padding-bottom:8px;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#000000;}
.ExitButton_e186vt19{outline:none !important;background:none;border:none;margin-right:12px;padding:0;border-radius:15px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.ExitButton_e186vt19 svg{width:12px;height:12px;}.ExitButton_e186vt19:focus,.ExitButton_e186vt19:active{box-shadow:0 0 0 4px rgb(255 255 255 / 33%);}
.ExitIcon_e1fhtoci path{fill:#000000;}.ExitIcon_e1fhtoci:hover path{fill:#474952;}
.Rule_rm97hmz{border-bottom:1px solid #d2d3d8;}
.RightMenu_r1ygzoyt{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.SourceDescription_s3fgbec{text-align:left;padding-top:10px;}
.Subtext_s197sw8c{width:460px;background-color:#dfe1e3;font-size:0.875rem;color:#858996;padding:12px 16px 12px 16px;min-height:66px;}.Subtext_s197sw8c hr{margin-top:0;}
