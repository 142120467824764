.IndicatorContainer_i1kib09n{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding-left:5px;}
.Indicator_i1472fql{width:16px;height:16px;border-radius:50%;border:1px solid #D2D3D8;background:var(--i1472fql-0);}
.Subtitle_s2hlpbz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:5px;margin:0;color:var(--s2hlpbz-0);font-size:0.75rem;max-width:inherit;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;min-height:18px;}@media (max-width:768px){.Subtitle_s2hlpbz{overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;}}
.AvatarAndTitle_aggba61{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0.5rem;}@media (max-width:768px){.AvatarAndTitle_aggba61{-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}}
.Header_h16ifpl2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0.75rem;color:#0E1A29;}@media (max-width:768px){.Header_h16ifpl2{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}}
.TitlesContainer_tuqvcml{max-width:17rem;width:17rem;}
.CustomHeading_c18misaa{display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;word-break:none;overflow:hidden;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;hyphens:auto;color:var(--c18misaa-0);}
.QueuedIcon_q1i7w9ni{width:10px;height:10px;background:#4b7a94;border-radius:50%;}
.InProgressIcon_ikozqvz{width:10px;height:10px;border:1px solid #28effa;border-radius:50%;}
.FailedIcon_f1cxc5ar{width:10px;height:10px;}
.StatusContainer_s1k3sc5p{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:5px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
