.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin: 0.1rem;
  }
}
.tags {
  .more-items {
    font-size: $font_size_xs;
    color: $grey_hover;
    margin-left: 4px;
  }
  .sourceCardTag--extra-info {
    display: none !important;
  }
  &.tags--expanded {
    .sourceCardTag--extra-info {
      display: inline-flex !important;
    }
  }
}

.company-warning-tag {
  overflow: hidden;
  text-overflow: ellipsis;
}
.company-status {
  margin-bottom: 4px;
}
.sourceCardTag.company-status.companyStatusWarning {
  &.sourceCardTag--extra-info {
    margin-right: 4px;
  }
}
.companyStatusWarning {
  &:hover {
    .full-text {
      display: block;
      position: absolute;
      padding: 8px;
      background-color: $white;
      color: $grey_dark;
      top: 10px;
      border-radius: 5px;
    }
  }
}
.tags--expanded {
  .full-text {
    display: none !important;
  }
}
