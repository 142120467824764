$margin-bottom__title: 6px;
$margin-bottom__field: 6px;

dl {
  font-size: $font-size_sm;
  line-height: 1.3;
  dt {
    margin-bottom: $margin-bottom__title;
    color: $grey_mid;
  }
  dd {
    margin-bottom: $margin-bottom__field;
    color: $grey_dark;
  }
}

.font {
  &__title {
    color: $grey_mid;
  }
  &__color {
    &--regular {
      color: $grey_mid;
    }
    &--dark {
      color: $grey_dark !important;
    }
    &--light {
      color: $grey_mid;
    }
  }
}

.align {
  &__right_to_panel {
    text-align: right;
    padding-right: 14px;
  }
}

.font {
  &__size {
    &--regular {
      font-size: $font-size_md;
    }
    &--small {
      font-size: $font_size_sm;
    }
    &--smallest {
      font-size: $font_size_sm;
    }
    &--tiny {
      font-size: $font_size_sm;
    }
    &--tinyer {
      font-size: $font_size_xs;
    }
    &--tinyest {
      font-size: $font_size_2xs;
    }
  }
  &__line-height {
    &--thin {
      line-height: $line_height__thin;
    }
    &--regular {
      line-height: $line_height__regular;
    }
  }

  &__title {
    font-size: $font-size_md;
    font-family: $InterDisplay;
    margin-bottom: $margin-bottom__title;
  }
  &__large-title {
    font-size: $font_size_lg;
    font-family: $InterDisplay;
    margin-bottom: 12px;
  }
  &__big-number {
    color: $gray_mid_42;
    font-size: $font_size_xl;
    font-family: $InterDisplay;
  }
  &__regular {
    font-size: $font-size_md;
    font-family: $InterDisplay;
    line-height: 1.25rem;
  }
}
