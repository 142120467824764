.EntityCardContainer_eugqbha{border-bottom:2px solid #ffffff;}.EntityCardContainer_eugqbha:last-child{border-bottom:none;}.EntityCardContainer_eugqbha:not(:focus-visible){outline:none;}
.EntityImage_e9ohzmw{background-color:var(--e9ohzmw-0);width:74px;height:74px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:3px;}
.FlagPillsContainer_fdnir25{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:6px;padding-top:11px;}
.RightChevron_r4cnvk{height:11px;width:11px;margin:0 3px;margin-bottom:3px;}.RightChevron_r4cnvk path{fill:var(--r4cnvk-0);}
.ChildFlagPill_ca8d25i{margin-right:6px;}
.Chevron_c1e16prd{width:10px;-webkit-transform:var(--c1e16prd-0);-ms-transform:var(--c1e16prd-0);transform:var(--c1e16prd-0);}.Chevron_c1e16prd path{fill:#474952;}
.ConfidenceHitCount_c1f2bmxt{color:#474952;font-size:1.125rem;margin-right:8px;}
.ExpandHeader_e1s8olxv{all:unset;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 16px;font-size:1rem;color:#858996;height:48px;background-color:#dfe1e3;cursor:var(--e1s8olxv-0) !important;}.ExpandHeader_e1s8olxv .ConfidenceHitCount_c1f2bmxt,.ExpandHeader_e1s8olxv .Chevron_c1e16prd{color:var(--e1s8olxv-1);}.ExpandHeader_e1s8olxv .ConfidenceHitCount_c1f2bmxt path,.ExpandHeader_e1s8olxv .Chevron_c1e16prd path{fill:var(--e1s8olxv-2);}.ExpandHeader_e1s8olxv:not(:focus-visible){outline:none;}
.ExpandHeaderCountLabel_eisnnst{color:#474952;}
.ExpandHeaderRightContent_e346h21{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;opacity:var(--e346h21-0);cursor:var(--e346h21-1) !important;}.ExpandHeaderRightContent_e346h21 .ConfidenceHitCount_c1f2bmxt,.ExpandHeaderRightContent_e346h21 .Chevron_c1e16prd{color:var(--e346h21-2);}.ExpandHeaderRightContent_e346h21 .ConfidenceHitCount_c1f2bmxt path,.ExpandHeaderRightContent_e346h21 .Chevron_c1e16prd path{fill:var(--e346h21-3);}
.cardStyles_c11uf313{border-top:none !important;}
.InfoIconContainer_i1quev9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.DisabledInfoIconContainer_d12famd4.InfoIconContainer_i1quev9{opacity:0.2;}
.cardStylesNullified_cqxaaol{border-top:none !important;}.cardStylesNullified_cqxaaol #pdx-confidence-opacity{opacity:0.2;-webkit-transition:opacity 1s;transition:opacity 1s;}.confidence-opacity__transparent{opacity:0.2;-webkit-transition:opacity 1s;transition:opacity 1s;}.confidence-opacity__opaque{opacity:1;}
