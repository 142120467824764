.CardContainer_c1dc23xb{margin:0;padding:3px;cursor:default;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex:0 0 25%;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}.CardContainer_c1dc23xb:first-child{padding-left:0;}.CardContainer_c1dc23xb:nth-child(4n + 0){padding-right:0;}.CardContainer_c1dc23xb:nth-child(4n + 1){padding-left:0;}
.CardInnerContainer_c15sknje{width:100%;max-width:none;padding:16px;background-color:#ffffff;border-radius:4px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;height:100%;min-width:11.2rem;}
.CardContentContainer_c1y8xf0p{width:100%;height:100%;}
.CardContent_c9zxrzc{position:relative;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.CardTopSection_c1rbvvtr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;position:relative;height:82px;}
.CardImageSection_ccrol37{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;position:relative;gap:6px;}
.ImageContainer_ipi1n6p{width:95px;height:90px;}
.Image_i8jk8ul{height:74px;margin-bottom:8px;width:74px;object-fit:cover;border-radius:3px;}
.SocialMediaIcons_s1w3dp06{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.SocialMediaIcons_s1w3dp06 a{line-height:1;}
.RightSideContainer_r15w5idv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.CardSummary_cuwngwg{color:#858996;}
.NameField_n1izwhjk{margin-bottom:4px;font-size:1.125rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;}
.DataFieldValue_d13t6euj{font-size:0.875rem;padding-bottom:6px;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;}
.DescriptorValue_d1tf0ouj{border-top:1px solid #d2d3d8;padding-top:6px;margin-top:6px;}
.RoleDuration_rc4e9e2{color:#858996;}
.FieldLabel_fbsh2hf{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#858996;}
.Accordion_aopj0ll{margin-top:10px;border-top:1px solid #d2d3d8;padding:3px 0;}
.AdditionalFieldContainer_a1458jl9{padding-top:10px;}
.PlaceholderIcon_pqk0uip{height:74px;margin-bottom:8px;width:74px;object-fit:cover;border-radius:3px;}
.SummaryFields_slrkh2m{min-height:30px;}
