.CardContent_c8wwhjn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;}
.CardTopSection_cbgh2mc{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.DescriptionSection_dad1n0n{margin-top:8px;font-size:0.875rem;color:#474952;word-break:break-word;}
.SocialMediaWithInspector_s5j4757{padding-top:6px;}
.SocialMediaIconsContainer_s1odeajw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;pointer-events:none;}
.CardSummary_c1gn9qjl{color:#858996;}
.NameField_n19yulbm{font-size:1rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;max-height:24px;}
.DurationPills_d4mbtff{text-align:left;line-height:1.25;padding:4px 0;}
.NoDate_noughss{color:#858996;}
.RoleDuration_r7fmyq7{color:var(--r7fmyq7-0) !important;font-size:0.875rem;}.RoleDuration_r7fmyq7 .NoDate_noughss{color:var(--r7fmyq7-1);}
.PillsContainer_p3wtdjd{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.RolePill_rqgkq63{padding:0px 8px;border:1px solid #b3b5bd;border-radius:16px;margin-right:5px;-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;font-size:0.875rem;text-transform:capitalize;opacity:0.6;border-color:var(--rqgkq63-0);color:var(--rqgkq63-0);}.RolePill_rqgkq63:last-child{margin-right:0;}
.ListItem_lt6ryr1{margin-bottom:8px;padding:0;}.ListItem_lt6ryr1:first-child{margin-top:8px;}.ListItem_lt6ryr1:last-child{margin-bottom:6px;}
.RoleTitle_rdfh3ml{font-size:0.875rem;color:#474952;line-height:20px;}
.RoleDate_rs8qp56{font-size:0.875rem;}
.Rule_r1gbsdn0{color:#d2d3d8;margin:0;margin:8px 0;}
.Info_i10860sq{font-size:0.875rem;color:#858996;white-space:nowrap;}
.RolesSinceStartLabel_rm7kbs6{font-size:0.875rem;color:#858996;white-space:nowrap;}
.OnlineProfilesInfo_oa27nwe.Info_i10860sq{color:#007AFF;margin-top:3px;}
.PillsInfo_p1hnbxdc.Info_i10860sq{color:#858996;}
.LocationText_l161rea4{font-size:0.875rem;color:#858996;word-break:break-word;text-align:left;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.LocationName_l1vphxw1{max-width:180px;display:inline-block;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;padding-right:5px;}
.DescriptionContainer_drxhpt{margin-top:-0.5px;}
.MissingData_m13ysry1{font-size:0.875rem;font-style:italic;margin:0;}
.NoActiveRoles_n1fi86nr{font-size:0.875rem;color:#b3b5bd;}
.StyledWithInspector_s10coxbo{margin-top:8px;}
.InspectorSocialMediaIcons_i84c4m{padding:2px 0 3px 0;}.InspectorSocialMediaIcons_i84c4m a{color:var(--i84c4m-0) !important;}.InspectorSocialMediaIcons_i84c4m path{fill:var(--i84c4m-1) !important;}
.RoleContainer_r19h1gnf{padding-bottom:6px;}
.MediaHandlerContainer_m1jyeltv{padding-bottom:6px;}

.ContentContainer_c611ar2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;}.ContentContainer_c611ar2 .RoleTitle_rdfh3ml,.ContentContainer_c611ar2 .NameField_n19yulbm,.ContentContainer_c611ar2 .DescriptionSection_dad1n0n{color:var(--c611ar2-0);}.ContentContainer_c611ar2 .PillsInfo_p1hnbxdc,.ContentContainer_c611ar2 .LatestRoleDuration_lej89nh,.ContentContainer_c611ar2 .RolesSinceStartLabel_rm7kbs6,.ContentContainer_c611ar2 .LocationText_l161rea4,.ContentContainer_c611ar2 .MissingData_m13ysry1{color:var(--c611ar2-1) !important;}.ContentContainer_c611ar2 .SocialMediaIconsContainer_s1odeajw{-webkit-filter:var(--c611ar2-2);filter:var(--c611ar2-2);opacity:var(--c611ar2-3);}
.pillClassName_p1mwg168{border-radius:16px;padding:3px 10px;}
