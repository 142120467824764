.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: $black;
  -webkit-tap-highlight-color: transparent;

  .react-toggle-track {
    height: 20px;
    width: 32.5px;
    background-color: $grey_rule;
  }

  .react-toggle-thumb {
    height: 16px;
    width: 16px;
    top: 2px;
    margin: 0 2px;
    border: none;
  }

  &--checked {
    .react-toggle-track-check {
      opacity: 1;
      -webkit-transition: opacity 0.25s ease;
      -moz-transition: opacity 0.25s ease;
      transition: opacity 0.25s ease;
    }
    .react-toggle-track-x {
      opacity: 0;
    }
    .react-toggle-thumb {
      left: 12px;
      border-color: $blue_icon;
    }
  }
  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  &--focus {
    .react-toggle-thumb {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
  &--checked .react-toggle-track {
    background-color: $green_light_tag_bg;
  }

  &:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  &-track {
    width: 30px;
    height: 14px;
    padding: 0;
    border-radius: 20px;
    background-color: $grey_dark_mid;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &-track-check {
    position: absolute;
    width: 9px;
    height: 7px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 4px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  &-track-check {
    position: absolute;
    width: 9px;
    height: 7px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 4px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  &-track-x {
    position: absolute;
    width: 7px;
    height: 7px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 5px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  &-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 14px;
    height: 14px;
    border: 1px solid $grey_dark_mid;
    border-radius: 50%;
    background-color: $grey_lightest;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
}
