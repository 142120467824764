.Rule_rsq2v98{width:100%;height:1px;background-color:#858996;}
.BuildingIcon_bx0cmp7{-webkit-transform:scale(0.8);-ms-transform:scale(0.8);transform:scale(0.8);}.BuildingIcon_bx0cmp7 path{fill:#ffffff;}
.Inputs_i1spckv0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;border-top:1px solid #858996;border-bottom:1px solid #858996;padding:1rem 0;}
.InputGroup_i1bhwjmz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.Actions_a1tpc61f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;}.Actions_a1tpc61f > button{-webkit-flex:1;-ms-flex:1;flex:1;}
.NotifyOwnerOption_n92rxww{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0.5rem;font-size:0.875rem;}.NotifyOwnerOption_n92rxww input{width:1.125rem;height:1.125rem;}.NotifyOwnerOption_n92rxww input:checked{accent-color:#28effa;}.NotifyOwnerOption_n92rxww label{margin:0;}
.ConfirmedCopyModalContent_c1sji70h{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;border-top:1px solid #858996;padding-top:1.5rem;}
.AccessLevel_a17y187e{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;font-size:0.875rem;padding-left:3.875rem;padding-right:1rem;}.AccessLevel_a17y187e > div:first-of-type{color:#d2d3d8;}
.NotifyOwnerConfirmation_n10v298i{margin:0;color:#b3b5bd;font-size:0.875rem;}
.selectGroupInput_s1ho8wqi{padding:0.5rem 0.75rem;min-height:3rem;}
