.snapshot-section {
  .two-columns {
    background-color: $grey_panel;
    column-count: 2;
    column-gap: 0px;
    width: 100%;
  }

  .panel-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .panel {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: $grey_panel;
    page-break-inside: avoid;
  }
  .max-height {
    overflow-x: hidden;
    overflow-y: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }
  .white-border {
    border: 1px solid white;
  }

  .expand-wrapper {
    display: flex;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 3;
    background: $grey_panel;
    padding-left: 8px;
    padding-bottom: 8px;

    a:hover {
      cursor: pointer;
    }
    svg {
      background: $white;
      width: 22px;
      height: 22px;

      &:hover {
        path {
          fill: $grey_dark;
        }
      }
    }
  }

  .transition-all {
    transition: all 0.3s ease-in-out;
  }
}
.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-scroll-zoom-blocker,
.mapboxgl-touch-pan-blocker {
  color: #fff;
  font-family: $Inter_Regular;
  justify-content: center;
  text-align: center;
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.75s ease-in-out;
  transition-delay: 1s;
  font-size: $font_size_lg !important;
}

.mapboxgl-scroll-zoom-blocker-show,
.mapboxgl-touch-pan-blocker-show {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.snapshot__director-widget-container {
  width: 100%;
  padding: 0 1px;
  margin-bottom: 8px !important;
}

.snapshot__directorship-header-container {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}

.snapshot__directorship-bars {
  width: 100%;
  display: flex;
}

.snapshot__directorship-bars-container {
  width: 100%;
  padding-right: 25px;
}

.snapshot__employment-details-container {
  width: 100%;
  padding-left: 25px;
}

.snapshot__web-and-media-container {
  margin-bottom: 8px !important;
  width: 100%;
}

.snapshot__images-container {
  margin-bottom: 8px !important;
}

.snapshot__associated-industries-container {
  padding-right: 8px;
  margin-bottom: 8px !important;
}
