.sort-filter-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1/-1;
}

.sort-filter-bar-row {
  padding: 2px 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.sort-filter-bar-toggles-group {
  display: flex;
}

.sort-filter-bar-toggle-container {
  margin: 2px 8px 2px 4px; // roughly aligns (very slightly indented) with edge of pill above, not pill text
}

.sort-filter-reset-all-button {
  border: none;
  background-color: transparent;
  margin-left: auto;

  &:focus {
    outline: none;
  }
}
