.XapienLogo_x1ki9mzf{width:48px;height:48px;}.XapienLogo_x1ki9mzf path{fill:#28effa;}
.Container_c2b3083{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:1rem;}
.Description_d810wr1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;text-align:center;}
.Warning_wl411i3{text-align:center;}
.Inputs_izb2ts0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;width:100%;margin-bottom:1rem;}.Inputs_izb2ts0 input{width:100%;}
.Subtitle_s18syc8c{color:#b3b5bd;font-size:0.875rem;margin:0;}
.Title_tdao6ur{color:#ffffff;}
.PasswordInvalid_p1d24t4v{width:100%;color:#ff9d00;}
.PasswordValid_p1elh7o9{width:100%;color:#28e4fa;}
.Link_l1ggi65t{color:#28e4fa;}
.Explainer_erv58oy.Subtitle_s18syc8c{text-align:center;}
