.ModalContent_m12mcp72{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;max-height:34.375rem;overflow:auto;}
.Text_t15l7lso{color:#b3b5bd;font-size:0.875rem;line-height:1.375rem;margin:0;}
.CustomButton_czziyu2{-webkit-align-self:flex-start;-ms-flex-item-align:start;align-self:flex-start;}
.Form_f12gn6j8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.FieldContainer_f1o96bst{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;max-height:4.8125rem;}
.Field_f1edrmgh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:6px;background:#EDEDED;font-style:normal;font-weight:400;font-size:1rem;line-height:24px;color:#858996;padding:12px;}
.FieldLabel_f13uztys{color:#ffffff;font-size:0.875rem;}
.ResetPassword_r6tadzc{all:unset;color:#007AFF;font-size:0.875rem;cursor:pointer;}.ResetPassword_r6tadzc:hover{-webkit-text-decoration:underline;text-decoration:underline;}.ResetPassword_r6tadzc:not(:focus-visible){outline:none;}
.ResetPasswordText_r1myh11v{margin:0;color:#b3b5bd;font-size:0.875rem;}
.ResetPasswordSentText_ryn0yju.ResetPasswordText_r1myh11v{color:#28effa;}
