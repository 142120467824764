div#surveyContent {
  display: none;
}
.shell-inner-report {
  height: calc(100vh - 150px);
}
@media print {
  .shell-inner-report {
    height: unset;
  }
}
@media screen {
  .report-scroll-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    // overflow:auto;
    // -webkit-overflow-scrolling: touch;
  }
  .report-outer-container {
    margin-bottom: 35px;
    border-radius: 6px;
  }
}

.report-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 20px;
  width: 100%;
  max-width: 1080px; // This is referenced in Section.js, make sure its same
  background-color: $white;
  color: $grey_dark;
  min-height: -webkit-max-content; /*Needed for Safari to show the background at the right length*/

  > * {
    width: 100%;
  }
  .report-inner-container {
    flex-grow: 1;
    padding: 0 25px;
  }
}
.pilot-report-message-container {
  display: block;
  text-align: center;
  color: $grey_dark;
  max-width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 2rem;
  font-size: $font_size_sm;

  @media print {
    display: none;
  }
}
