.SingleImageContainer_s1e90vwi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.FullImage_f1bcx9xq{object-fit:cover;height:100%;width:100%;padding-bottom:4px;}
.ImageColumn_i1oxs7ou{-webkit-flex-basis:var(--i1oxs7ou-0);-ms-flex-preferred-size:var(--i1oxs7ou-0);flex-basis:var(--i1oxs7ou-0);width:var(--i1oxs7ou-0);}
.InfoColumn_i12pkmf5{width:400px;margin-left:16px;}
.ImageInformation_ixg2ar{overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
.ImageDocumentTitle_i1brvumf.ImageInformation_ixg2ar{font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;margin-bottom:8px;}
.ImageAltText_iutu7c.ImageInformation_ixg2ar{font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#858996;margin-bottom:8px;}
.ImageDate_i1tp1d92.ImageInformation_ixg2ar{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#858996;margin-bottom:8px;}
.ImageOriginUrl_i1betvx6.ImageInformation_ixg2ar{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;margin-bottom:8px;}
.ImageThirdParty_i75jaab.ImageInformation_ixg2ar{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#dfe1e3;}
.ImageSources_ir4w5z0.ImageInformation_ixg2ar{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;margin-top:8px;}
