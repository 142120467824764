.report-image {
  display: flex;
  flex-direction: row;
  justify-content: center; /*flex-start;*/
  flex-wrap: wrap;
}

.image-container {
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .image {
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
