.CardContentContainer_c18xyb7h{font-size:0.875rem;color:#474952;}
.OrgName_o1windiy{font-size:1rem;color:#474952;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.DataKey_ddedzl1{color:#858996;}
.Industry_i119u2da{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;white-space:pre-wrap;}
.NoneIdentified_n6o6mvl{color:#b3b5bd;font-style:italic;}
.MidContent_miufl6z{display:grid;grid-template-columns:repeat(2,1fr);border-top:1px solid #d2d3d8;padding-top:8px;margin-top:8px;}
.Column_carya0k > div:not(:first-child){margin-top:8px;}
.ParentName_p1k7zc0j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-width:180px;}
.ParentNamesAdditionalCount_p18pztcb{color:#858996;white-space:pre;}
.OrgStatus_o1n0si6q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;border-top:1px solid #d2d3d8;padding-top:10px;margin-top:8px;row-gap:6px;}
.Pill_pjilcrj{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:22px;padding:0px 8px;border-radius:16px;margin-right:6px;-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;font-size:0.875rem;max-width:165px;}
.RiskPill_retr6t7.Pill_pjilcrj{border:1px solid #ef7273;color:#ef7273;}
.StatusPill_s1svy9ya.Pill_pjilcrj{border:1px solid var(--s1svy9ya-0);color:var(--s1svy9ya-0);opacity:0.6;}
.OrganisationType_oybmsno{max-width:170px;}
.IndustryValue_i33kdno{max-width:220px;}
.TopContent_t1fzcbn0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
