.CardInnerContainer_c4pno0g{width:100%;max-width:none;padding:16px;background-color:var(--c4pno0g-0);border-radius:3px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;height:100%;min-width:11.2rem;min-height:var(--c4pno0g-1);height:var(--c4pno0g-2);cursor:var(--c4pno0g-3);}
.CardContentContainer_c1qcu2vo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;}
.CardImageSection_caz8nmk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:9px;}
.ImageContainer_ich1adq{display:relative;width:74px;height:74px;}
.Image_i15ckpq2{height:74px;margin-bottom:8px;width:74px;object-fit:cover;border-radius:3px;}
.Container_c1bdpa8d{margin-right:12px;}
.PlaceholderIcon_p16rpj8p{height:74px;margin-bottom:8px;width:74px;object-fit:cover;border-radius:3px;}
.CardContainer_ceng2nq{margin:0;cursor:default;-webkit-break-inside:avoid;break-inside:avoid;visibility:var(--ceng2nq-0);}.CardContainer_ceng2nq .CardInnerContainer_c4pno0g{border-top:3px solid var(--ceng2nq-1);}.CardContainer_ceng2nq .PlaceholderIcon_p16rpj8p{opacity:var(--ceng2nq-2);}.CardContainer_ceng2nq .Image_i15ckpq2{-webkit-filter:var(--ceng2nq-3);filter:var(--ceng2nq-3);opacity:var(--ceng2nq-4);}
.StyledWithInspector_skcv26r{margin-top:8px;}
.InspectorSocialMediaIcons_i1i0hgki a{color:var(--i1i0hgki-0) !important;}.InspectorSocialMediaIcons_i1i0hgki path{fill:var(--i1i0hgki-1) !important;}
.CardContent_ckdmxe{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;}
