.Menu_m16nxaqm{overflow-y:scroll;max-height:190px;}
.Container_c1mq0ud8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.Title_thwy2wk{color:#ffffff;}
.Buttons_been5u1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;gap:1rem;}.Buttons_been5u1 button{-webkit-flex:1;-ms-flex:1;flex:1;}
.Divider_d1iyhjy8{border-top:1px solid #ffffff;marign:0;}
.Optional_o1s5djza{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.Subtext_svpnk95{color:#b3b5bd;font-size:0.875rem;}
.UserSearch_u1eeedie{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;}
.Autocomplete_a1vrjl4l{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:0.375rem;background-color:#EDEDED;border:none;padding:0 1rem 0 0;}.Autocomplete_a1vrjl4l .MuiAutocomplete-root{-webkit-flex:1;-ms-flex:1;flex:1;}.Autocomplete_a1vrjl4l .MuiOutlinedInput-notchedOutline{border:0;}.Autocomplete_a1vrjl4l .MuiOutlinedInput-root .MuiAutocomplete-input{padding-top:5px !important;padding-bottom:2px !important;}
.OptionsButton_o1xryclk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:0.875rem !important;line-height:1.125rem;color:#0E1A29 !important;all:unset;white-space:nowrap;cursor:pointer;}.OptionsButton_o1xryclk:not(:focus-visible){outline:none;}
.Emails_e157gboa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:1rem;}
.Email_eyivfzv{background:#0E1A29;padding:8px 10px 8px 8px;border-radius:4px;color:#d2d3d8;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.5rem;}
.Groups_g6agl70{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;}
.GroupsPopover_g1v40a7v{-webkit-align-self:flex-start;-ms-flex-item-align:start;align-self:flex-start;}
