.CountryContainer_cxindur{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#474952;}
.Accordion_a1wxxmqe{border-bottom:none !important;padding-top:13px !important;margin-left:-16px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}.Accordion_a1wxxmqe > div{margin-left:var(--a1wxxmqe-0);}.Accordion_a1wxxmqe > button{-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;-webkit-box-pack:start;-webkit-justify-content:start;-ms-flex-pack:start;justify-content:start;gap:3px;}
.PreviousNamesAccordion_p9y6mpe.Accordion_a1wxxmqe{padding-top:2px !important;padding-bottom:0 !important;margin-top:4px;}.PreviousNamesAccordion_p9y6mpe.Accordion_a1wxxmqe > button{color:#4674ba;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.Address_arv82sq{font-size:0.875rem;color:#858996;margin-left:47px;margin-top:4px;}
.OrgIconContainer_o3uwogp{min-width:36px;height:36px;border-radius:3px;background-color:#19a49b;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:8px;}
.OrgIcon_o19y1kus{-webkit-transform:scale(0.65);-ms-transform:scale(0.65);transform:scale(0.65);}
.InfoSummaryRow_ic3u79u:not(:last-of-type){margin-bottom:8px;}.InfoSummaryRow_ic3u79u ul{padding:0 14px;margin:0;}
.CompanyLocation_c7vpzis{color:#858996;margin-bottom:8px;}
.StatusTags_svl90z8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:8px;margin-top:8px;}
.StatusTag_s87o137{--statuscolor:var(--s87o137-0);border-radius:11px;background-color:transparent;border:1px solid var(--statuscolor);color:var(--statuscolor);padding:0 8px;font-size:0.875rem;line-height:18px;height:22px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;max-width:165px;}
.CustomFlag_c1yqjvo{width:31px;}.CustomFlag_c1yqjvo img{width:24px !important;height:16px !important;margin:0;margin-top:1px !important;margin-right:5px !important;border-radius:2px !important;}
.PreviousName_p1ycenrf{color:#4674ba;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;}
.SinglePreviousName_s3k98kz.PreviousName_p1ycenrf{margin-top:4px;}
.Text_t1k0jziq{color:#474952;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.PreviousNamesList_p14mi8xn{margin-left:52px !important;}
.ListItem_l16q7ty2::marker{color:#858996;font-size:1rem;}
.SingleAddressContainer_s1whx8hv{padding-top:13px;padding-bottom:3px;}
.Date_d1frn2q9{color:#858996;}
.PersonDetails_p925s77{font-size:0.875rem;border-top:1px solid #d2d3d8;padding-top:10px;margin-top:12px;}.PersonDetails_p925s77 > div:not(:first-child){margin-top:12px;}
.OfficershipRole_o132ix7c + .OfficershipRole_o132ix7c{margin-top:4px;}
.Role_r128e1o0{color:#858996;}
.RoleDate_r3d5t9a{color:var(--r3d5t9a-0);}
.RoleDuration_r1j9vrq2{font-size:0.875rem;color:#858996;}
.CompanyIdentifier_ciq77ir{padding-top:5px;}
.Label_l1cyugq9{color:#858996;}
.IndustryType_i1qtvmw{color:#858996;}
.RolePill_rtd4j5o{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin:4px 0;padding:0px 8px;border:1px solid #b3b5bd;border-radius:16px;margin-right:5px;-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;font-size:0.875rem;text-transform:capitalize;opacity:0.6;border-color:var(--rtd4j5o-0);color:var(--rtd4j5o-0);}.RolePill_rtd4j5o:last-child{margin-right:0;}
