.EventTitle_e7y9qq8{font-size:1.125rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;display:block;margin-bottom:5px;}
.EventBucket_ezfau2w{background:#858996;color:#ffffff;font-size:0.75rem;padding:2px 8px;border-radius:4px;margin-bottom:5px;display:inline-block;}
.EventSubtitle_e1yqfxbf{margin-bottom:0;font-size:0.875rem;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#858996;padding-bottom:6px;}
.DeleteButton_d11xl0sv{position:absolute;padding:6px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;text-align:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;background:#ffffff;border-radius:26px;box-shadow:var(--d11xl0sv-0);border:none;opacity:0;z-index:2;left:var(--d11xl0sv-1);right:var(--d11xl0sv-2);bottom:-8px;}.DeleteButton_d11xl0sv:focus{outline:none;}.DeleteButton_d11xl0sv:hover{background-color:#dfe1e3;}
.DeleteModalEventContent_d1vj4psp{font-size:0.875rem;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;margin-bottom:6px;}
.EventContent_e1yaed1z.DeleteModalEventContent_d1vj4psp{padding-right:var(--e1yaed1z-0);margin-right:var(--e1yaed1z-1);}
.ContentDiv_c1p6j5k0{padding-top:5px;padding-bottom:5px;padding-left:var(--c1p6j5k0-0);padding-right:var(--c1p6j5k0-1);width:100%;}.ContentDiv_c1p6j5k0 .InspectorInnerContainer_i13b6beu{padding:10px;padding-left:var(--c1p6j5k0-2);padding-right:var(--c1p6j5k0-3);}.ContentDiv_c1p6j5k0 .InspectorInnerContainer_i13b6beu:hover,.ContentDiv_c1p6j5k0 .InspectorInnerContainer_i13b6beu:focus{background-color:var(--c1p6j5k0-4);border-radius:10px;box-shadow:0 0 1px rgba(116,57,179,0.07);cursor:pointer;}.ContentDiv_c1p6j5k0 .InspectorInnerContainer_i13b6beu:hover > [class*="DeleteButton"],.ContentDiv_c1p6j5k0 .InspectorInnerContainer_i13b6beu:focus > [class*="DeleteButton"]{opacity:1;}
.SourcesSubtitle_srte32y{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;text-transform:lowercase;}
.TrashIcon_t1sxdvux{width:18px;height:18px;opacity:var(--t1sxdvux-0);color:var(--t1sxdvux-1);}
.WrapperForModal_w7cc1jm{position:absolute;right:var(--w7cc1jm-0);}
